import React from 'react'
import { withBemMod } from '@bem-react/core'

import Heading from '../../../Heading'
import Paragraph from '../../../Paragraph'

import { PageContent } from '../../-Content/Page-Content'
import { PageFooter } from '../../-Footer/Page-Footer'
import { PageHeader } from '../../-Header/Page-Header'
import { cnPage } from '../../Page'

import ancapSmiley from './images/ancap-smiley.png'
import './Page_name_success.css'

const newBody = (Base, props) => (
  <Base {...props}>
    <PageHeader theme='default' activeLink='join' />
    <PageContent tag='main'>
      <div className={cnPage('Message')}>
        <img
          alt='Чёрно-жёлтый колобок в солнцезащитных очках
            показывает большой палец вверх'
          className={cnPage('Smiley')}
          src={ancapSmiley} />
        <section>
          <Heading className={cnPage('Head')} size='m' tag='h1'>
            Супер, осталось подтвердить почту!
          </Heading>
          <Paragraph theme='default' size='m'>
            Чтобы мы&nbsp;рассмотрели вашу заявку, подтвердите адрес
            электронной почты. Для этого перейдите по&nbsp;ссылке
            в&nbsp;письме, которое мы&nbsp;вам отправили.
          </Paragraph>
        </section>
      </div>
    </PageContent>
    <PageFooter />
  </Base>
)

export const PageNameSuccess = withBemMod(
  cnPage(),
  { name: 'success' },
  newBody
)
