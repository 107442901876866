import React from 'react'

import { cnButton } from '../Button'
import './Button-Text.css'

export const ButtonText = (props) => (
  <span className={cnButton('Text')}>
    {props.children}
  </span>
)
