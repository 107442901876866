import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnLogo } from '../Logo'
import './Logo_theme_negative.css'

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.iconTheme = 'negative'

  return <Base {...newProps} />
}

export const LogoThemeNegative = withBemMod(
  cnLogo(),
  { theme: 'negative' },
  newBody
)
