import React from 'react'
import { cn } from '@bem-react/classname'

import Link from '../Link'
import Logo from '../Logo'
import Menu from '../Menu'
import { HeaderActions } from './-Actions/Header-Actions'
import { HeaderBurger } from './-Burger/Header-Burger'
import { HeaderSections } from './-Sections/Header-Sections'
import './Header.css'

export function cnHeader (...args) {
  return cn('Header')(...args)
}

export class Header extends React.Component {
  constructor (props) {
    super(props)
    this.handleBurgerClick = this.handleBurgerClick.bind(this)
    this.handleMenuClose = this.handleMenuClose.bind(this)
    this.state = { isMenuOpen: false }
  }

  handleBurgerClick () {
    this.setState({ isMenuOpen: true })
  }

  handleMenuClose () {
    this.setState({ isMenuOpen: false })
  }

  render () {
    return (
      <header className={cnHeader(null, [this.props.className])}>
        <Link href='/'>
          <Logo theme={this.props.logoTheme}>
            Либертарианская партия
          </Logo>
        </Link>
        <HeaderSections activeLink={this.props.activeLink} />
        <HeaderActions activeLink={this.props.activeLink} />
        <HeaderBurger
          theme={this.props.burgerTheme}
          onClick={this.handleBurgerClick} />
        <Menu
          activeItem={this.props.activeLink}
          closed={!this.state.isMenuOpen}
          onClose={this.handleMenuClose} />
      </header>
    )
  }
}
