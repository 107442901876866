import React from 'react'

import { cnUList } from '../UList'
import './UList-Item.css'

export const UListItem = (props) => (
  <li className={cnUList('Item')}>
    {props.children}
  </li>
)
