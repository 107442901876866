import { compose } from '@bem-react/core'

import { Social as BaseSocial } from './Social'
import { SocialMediumFacebook } from './_medium/Social_medium_facebook'
import { SocialMediumInstagram } from './_medium/Social_medium_instagram'
import { SocialMediumOk } from './_medium/Social_medium_ok'
import { SocialMediumTelegram } from './_medium/Social_medium_telegram'
import { SocialMediumTwitter } from './_medium/Social_medium_twitter'
import { SocialMediumVk } from './_medium/Social_medium_vk'
import { SocialMediumYoutube } from './_medium/Social_medium_youtube'
import { SocialSizeM } from './_size/Social_size_m'
import { SocialSizeS } from './_size/Social_size_s'

const Social = compose(
  SocialMediumFacebook,
  SocialMediumInstagram,
  SocialMediumOk,
  SocialMediumTelegram,
  SocialMediumTwitter,
  SocialMediumVk,
  SocialMediumYoutube,
  SocialSizeM,
  SocialSizeS
)(BaseSocial)

export default Social
