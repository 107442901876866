import React from 'react'
import { cn } from '@bem-react/classname'

import { QuoteSource } from './-Source/Quote-Source'
import { QuoteText } from './-Text/Quote-Text'
import './Quote.css'

export const cnQuote = cn('Quote')

export const Quote = (props) => (
  <figure className={cnQuote(null, [props.className])}>
    <QuoteText>{props.children}</QuoteText>
    {props.source && <QuoteSource>{props.source}</QuoteSource>}
  </figure>
)
