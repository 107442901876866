import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnSocial } from '../Social'

const TwitterIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 42'>
    <g fill='none' fillRule='nonzero'>
      <rect fill='#69ace0' x='0' y='0' height='42' width='42' />
      <path fill='#fff'
        d='M 27.667,16.333
           l 1.666,-0.333
           s -1.4,1.567 -1.666,1.667
           c -0.034,0.766 0,1 0,1
             -3.2,13.733 -15,7.666 -15,7.666
             4.3,0.1 4.666,-1.666 4.666,-1.666
             -2.5,-0.367 -3,-2 -3,-2
             0.867,0.4 1.334,-0.334 1.334,-0.334
             -2.7,-0.7 -2.334,-3.333 -2.334,-3.333
             0.467,0.4 1.2,0.567 1.634,0.633
             -0.3,-0.366 -2.834,-3.4 -1.3,-4.966
             0,0 1.866,3.333 6.766,3.633
           l 0.334,-0.067
           C 20.7,18 20.7,17.767 20.7,17.5
           a 3.5 3.5 0 0 1 3.5,-3.5
           c 2.333,0 2.833,1.333 2.833,1.333
           l 1.667,-1
           c -0.033,0 -0.2,1.667 -1.033,2
           z' />
    </g>
  </svg>
)

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.icon = <TwitterIcon />

  return <Base {...newProps} />
}

export const SocialMediumTwitter = withBemMod(
  cnSocial(),
  { medium: 'twitter' },
  newBody
)
