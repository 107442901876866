import React from 'react'
import { compose } from '@bem-react/core'

import { cnHome } from '../Home'
import { HomeTabsDesktop } from './_desktop/Home-Tabs_desktop'
import { HomeTabsMobile } from './_mobile/Home-Tabs_mobile'
import './Home-Tabs.css'

const BaseHomeTabs = (props) => (
  <div className={cnHome('Tabs', [props.className])}>
    {props.children}
  </div>
)

export const HomeTabs = compose(
  HomeTabsDesktop,
  HomeTabsMobile
)(BaseHomeTabs)
