import React from 'react'

import { cnContacts } from '../Contacts'
import './Contacts-Label.css'

export const ContactsLabel = (props) => (
  <div className={cnContacts('Label')}>
    {props.children}
  </div>
)
