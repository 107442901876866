import React from 'react'
import { withBemMod } from '@bem-react/core'

import Contact from '../../../Contact'
import Contacts from '../../../Contacts'
import FileDL from '../../../FileDL/index.js'
import Heading from '../../../Heading'
import UList from '../../../UList'
import Paragraph from '../../../Paragraph'

import { PageContent } from '../../-Content/Page-Content'
import { PageFooter } from '../../-Footer/Page-Footer'
import { PageHeader } from '../../-Header/Page-Header'
import { cnPage } from '../../Page'

import gevixman from './images/gevixman.jpg'
import gevixman2x from './images/gevixman@2x.jpg'
import svetov from './images/svetov.jpg'
import svetov2x from './images/svetov@2x.jpg'
import vorobyov from './images/vorobyov.jpg'
import vorobyov2x from './images/vorobyov@2x.jpg'

import logoPDF from './images/logo.pdf'
import logoPNG from './images/logo.png'
import logoSVG from './images/logo.svg'

import './Page_name_press.css'

const newBody = (Base, props) => (
  <Base {...props} >
    <PageHeader theme='default' activeLink='party' />
    <PageContent tag='main'>
      <Heading className={cnPage('Head')} size='l' tag='h1'>
        Для прессы
      </Heading>

      <Heading className={cnPage('Sub')} size='s' tag='h2'>
        Пресс-секретарь и&nbsp;спикеры
      </Heading>
      <Contacts className={cnPage('Contacts')}>
        <Contact
          name='Виктор Гевиксман'
          photo={gevixman}
          photo2x={gevixman2x}
          position='Пресс-секретарь'
          telegram='sdthakingthaking' />
        <Contact
          name='Виктор Воробьев'
          photo={vorobyov}
          photo2x={vorobyov2x}
          position='Представитель по международному сотрудничеству,
            депутат Госсовета Республики Коми'
          telegram='arvidsever' />
        <Contact
          email='svetov@lp-russia.org'
          name='Михаил Светов'
          photo={svetov}
          photo2x={svetov2x}
          position='Член Этического комитета партии, политолог,
            автор Ютуб&#8209;канала СВТВ'
          telegram='mr_libertarian' />
      </Contacts>

      <div className={cnPage('Dyad')}>
        <div className={cnPage('AboutParty')}>
          <Heading className={cnPage('Sub')} size='s' tag='h2'>
            Коротко о&nbsp;партии
          </Heading>
          <Paragraph className={cnPage('Par')} theme='default' size='m'>
            Либертарианская партия России (ЛПР) возникла в&nbsp;2008&nbsp;году,
            и&nbsp;на&nbsp;апрель 2019&#8209;го в&nbsp;ней состоит 850&nbsp;членов
            и&nbsp;250&nbsp;сторонников из&nbsp;72&nbsp;регионов России.
            Мы&nbsp;не&nbsp;лидерская, а&nbsp;идеологическая партия:
            нас связывают не&nbsp;конкретные личности, а&nbsp;общие идеи.
          </Paragraph>
          <Paragraph className={cnPage('Par')} theme='default' size='m'>
            Либертарианство&nbsp;— это система ценностей, постулирующая свободу,
            основанную на&nbsp;отсутствии агрессии и&nbsp;принуждения.
            Либертарианцы выступают за&nbsp;максимизацию личных
            и&nbsp;экономических свобод и&nbsp;минимизацию влияния государства
            на&nbsp;жизнь общества.
          </Paragraph>
          <Paragraph className={cnPage('Par')} theme='default' size='m'>
            Мы&nbsp;регулярно проводим лекции, дебаты, участвуем в&nbsp;Форумах
            Свободных Людей, Чтениях Адама Смита, Мемориальных Конференциях
            Айн Рэнд. Организовываем митинги, в&nbsp;том числе против блокировки
            Телеграма 30&nbsp;апреля 2018, против пенсионной реформы
            и&nbsp;налогового грабежа 29&nbsp;июля 2018.
          </Paragraph>
          <Paragraph className={cnPage('Par')} theme='default' size='m'>
            Члены партии избирались муниципальными депутатами:
          </Paragraph>
          <UList className={cnPage('List')}>
            <Paragraph theme='default' size='m'>
              Вера Кичанова в&nbsp;Москве в&nbsp;2012&nbsp;году;
            </Paragraph>
            <Paragraph theme='default' size='m'>
              Андрей Шальнев в&nbsp;Подмосковье в&nbsp;2014&nbsp;году;
            </Paragraph>
            <Paragraph theme='default' size='m'>
              Дмитрий Максимов и&nbsp;Дмитрий Петров в&nbsp;Москве
              в&nbsp;2017&nbsp;году;
            </Paragraph>
            <Paragraph theme='default' size='m'>
              Денис Луговский, Денис Смирных и&nbsp;Евгений Топоров
              в&nbsp;Санкт-Петербурге в&nbsp;2019&nbsp;году.
            </Paragraph>
          </UList>
          <Paragraph className={cnPage('Par')} theme='default' size='m'>
              Член партии Виктор Воробьёв стал депутатом Государственного
              Совета Республики Коми в&nbsp;2021&nbsp;году.
          </Paragraph>
          <Paragraph className={cnPage('Par')} theme='default' size='m'>
            Мы&nbsp;не&nbsp;сотрудничаем с&nbsp;системной оппозицией.
            Государство отказывается регистрировать нашу партию,
            мы&nbsp;пытались пять раз, последний в&nbsp;2020&nbsp;году.
            Наша цель&nbsp;— изменение существующей модели государственного
            устройства России мирными средствами в&nbsp;соответствии
            с&nbsp;либертарианскими ценностями.
          </Paragraph>
        </div>

        <div className={cnPage('Other')}>
          <Heading className={cnPage('Sub')} size='s' tag='h2'>
            Символика
          </Heading>
          <FileDL
            links={
              [
                {
                  filename: 'Логотип ЛПР.svg',
                  href: logoSVG,
                  text: 'Логотип.svg'
                },
                {
                  filename: 'Логотип ЛПР.pdf',
                  href: logoPDF,
                  text: 'Логотип.pdf'
                },
                {
                  filename: 'Логотип ЛПР.png',
                  href: logoPNG,
                  text: 'Логотип.png'
                }
              ]
            }
            license='CC BY 4.0'
            preview={logoPNG}
          />
        </div>
      </div>
    </PageContent>
    <PageFooter isPressButtonDisabled />
  </Base>
)

export const PageNamePress = withBemMod(
  cnPage(),
  { name: 'press' },
  newBody
)
