import React from 'react'

import { cnFooter } from '../Footer'
import './Footer-Paragraph.css'

export const FooterParagraph = (props) => (
  <p className={cnFooter('Paragraph')}>
    {props.children}
  </p>
)
