import React from 'react'
import {
  BLOCK_BUTTONS,
  Editor as MediumEditor,
  createEditorState
} from 'medium-draft'
import { convertToRaw, getDefaultKeyBinding } from 'draft-js'
import { cn } from '@bem-react/classname'

import { EditorPictureButton } from './-PictureButton/Editor-PictureButton'

import 'draft-js/dist/Draft.css'
import 'medium-draft/lib/index.css'
import './Editor.css'

export const cnEditor = cn('Editor')

export class Editor extends React.Component {
  constructor (props) {
    super(props)
    this.state = { editorState: createEditorState(initialContent()) }

    this.blockButtons = [{
      label: 'H1',
      style: 'header-one',
      icon: 'header',
      description: 'Heading 1'
    },
    {
      label: 'H2',
      style: 'header-two',
      icon: 'header',
      description: 'Heading 2'
    }].concat(BLOCK_BUTTONS)

    this.sideButtons = [{
      title: 'Upload picture',
      component: EditorPictureButton
    }]

    this.toolbarConfig = {
      block: [
        'header-one',
        'header-two',
        'unordered-list-item',
        'ordered-list-item',
        'blockquote'
      ],
      inline: [
        'BOLD',
        'ITALIC',
        'hyperlink'
      ]
    }

    this.onChange = this.onChange.bind(this)
  }

  componentDidMount () {
    this.setState({ editorState: createEditorState(loadContent()) })
  }

  onChange (editorState) {
    this.setState({ editorState })
    saveContent(this.state.editorState.getCurrentContent())
  }

  render () {
    return (
      <div className={cnEditor(null, [this.props.className])}>
        <MediumEditor
          blockButtons={this.blockButtons}
          editorKey={this.props.editorKey}
          editorState={this.state.editorState}
          onChange={this.onChange}
          keyBindingFn={keyBindingFn}
          placeholder={this.props.placeholder}
          sideButtons={this.sideButtons}
          stringToTypeMap={stringToTypeMap}
          stripPastedStyles
          toolbarConfig={this.toolbarConfig} />
      </div>
    )
  }
}

const keyBindingFn = (e) => {
  switch (e.keyCode) {
    case 66: // B
    case 68: // D
    case 72: // H
    case 73: // I
    case 74: // J
    case 77: // M
    case 79: // O
    case 85: // U
      return null
    default:
      return getDefaultKeyBinding(e)
  }
}

const stringToTypeMap = {
  '* ': 'unordered-list-item',
  '+ ': 'unordered-list-item',
  '- ': 'unordered-list-item',
  '1.': 'ordered-list-item',
  '1)': 'ordered-list-item'
}

const initialContent = () => {
  return {
    entityMap: {},
    blocks: [
      {
        text: '',
        key: 'init',
        type: 'unstyled',
        entityRanges: []
      }
    ]
  }
}

const debounce = (fn, wait) => {
  let timeout

  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      timeout = null
      fn.apply(null, args)
    }, wait)
  }
}

const saveContent = debounce((content) => {
  const postBody = document.getElementById('post_body')
  postBody.value = JSON.stringify(convertToRaw(content))
}, 1000)

const loadContent = () => {
  const postBody = document.getElementById('post_body')
  const content = postBody.value
  return content && JSON.parse(content)
}
