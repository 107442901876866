import React from 'react'

import Header from '../../Header'
import { cnPage } from '../Page'
import './Page-Header.css'

export const PageHeader = (props) => {
  const newProps = Object.assign({}, props)
  newProps.className = cnPage('Header')

  return <Header {...newProps} />
}
