import React from 'react'
import { cn } from '@bem-react/classname'

import './Paragraph.css'

export const cnParagraph = cn('Paragraph')

export const Paragraph = (props) => {
  const Tag = props.tag || 'p'

  return (
    <Tag className={cnParagraph(null, [props.className])}>
      {props.children}
    </Tag>
  )
}
