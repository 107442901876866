import React from 'react'

export const PictureIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 -256 1930 1930'>
    <g fill='#000' transform='matrix(1,0,0,-1,7.59322,1362.678)'>
      <path
        d='m 640,960
           q 0,-80 -56,-136
             -56,-56 -136,-56
             -80,0 -136,56
             -56,56 -56,136
             0,80 56,136
             56,56 136,56
             80,0 136,-56
             56,-56 56,-136
           z
           M 1664,576
           V 128
           H 256
           v 192
           l 320,320
             160,-160
             512,512
           z
           m 96,704
           H 160
           q -13,0 -22.5,-9.5
           Q 128,1261 128,1248
           V 32
           Q 128,19 137.5,9.5
             147,0 160,0
           h 1600
           q 13,0 22.5,9.5
             9.5,9.5 9.5,22.5
           v 1216
           q 0,13 -9.5,22.5
             -9.5,9.5 -22.5,9.5
           z
           m 160,-32
           V 32
           q 0,-66 -47,-113
             -47,-47 -113,-47
           H 160
           Q 94,-128 47,-81
             0,-34 0,32
           v 1216
           q 0,66 47,113
             47,47 113,47
           h 1600
           q 66,0 113,-47
             47,-47 47,-113
           z' />
    </g>
  </svg>
)
