import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnSocial } from '../Social'

const OkIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 42'>
    <g fill='none' fillRule='nonzero'>
      <rect fill='#e27e35' x='0' y='0' height='42' width='42' />
      <path fill='#fff'
        d='M 22.6445,24.56
           c 0.8285,-0.1885 1.625,-0.517 2.3605,-0.978 0.556,-0.3505
             0.724,-1.085 0.373,-1.6425 -0.3505,-0.558 -1.085,-0.726
             -1.6425,-0.3755 -1.665,1.048 -3.8085,1.046 -5.4735,0
             -0.558,-0.3505 -1.292,-0.182 -1.6425,0.3755 -0.3505,0.556
             -0.182,1.292 0.373,1.6425 0.734,0.4615 1.5315,0.7895 2.3605,0.978
           L 17.083,26.8425
           c -0.4655,0.4655 -0.4655,1.2315 0,1.6835 0.234,0.231 0.5375,0.3485
             0.841,0.348 0.305,0 0.609,-0.117 0.843,-0.348
           L 21,26.293 23.2335,28.5265
           c 0.465,0.465 1.218,0.465 1.6835,0 0.465,-0.465 0.465,-1.22
             0,-1.6835
           L 22.645,24.57
           M 21,15.508
           c 0.929,0 1.6835,0.755 1.6835,1.6835 0,0.927 -0.755,1.6815
             -1.6835,1.6815 -0.927,0 -1.6835,-0.755 -1.6835,-1.6815 0,-0.929
             0.757,-1.6835 1.6835,-1.6835
           z
           m 0,5.7465
           c 2.2415,0 4.065,-1.823 4.065,-4.0625
           C 25.065,14.948 23.2415,13.125 21,13.125
           c -2.2415,0 -4.065,1.823 -4.065,4.065 0,2.2415 1.823,4.065 4.065,4.065
           z' />
    </g>
  </svg>
)

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.icon = <OkIcon />

  return <Base {...newProps} />
}

export const SocialMediumOk = withBemMod(
  cnSocial(),
  { medium: 'ok' },
  newBody
)
