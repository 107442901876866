import React from 'react'
import { convertToHTML as draftConvert } from 'draft-convert'

import Bold from '../../Bold'
import Heading from '../../Heading'
import Illust from '../../Illust'
import Italic from '../../Italic'
import Link from '../../Link'
import Paragraph from '../../Paragraph'
import Quote from '../../Quote'

import { cnPost } from '../Post'

const styleToHTML = (style) => {
  switch (style) {
    case 'BOLD':
      return <Bold />
    case 'ITALIC':
      return <Italic />
    default:
      return null
  }
}

const blockToHTML = (block) => {
  switch (block.type) {
    case 'header-one':
      return <Heading className={cnPost('Sub')} size='m' tag='h2' />
    case 'header-two':
      return <Heading className={cnPost('SubSub')} size='s' tag='h3' />
    case 'atomic:image':
      return <Illust uri={block.data.src} />
    case 'blockquote':
      return <Quote className={cnPost('Quote')} />
    case 'ordered-list-item':
      return {
        start: '<li class="OList-Item"><p class="Paragraph Paragraph_size_m Paragraph_theme_default">',
        end: '</p></li>',
        nest: 'nest',
        nestStart: '<ol class="OList Post-List">',
        nestEnd: '</ol>'
      }
    case 'unordered-list-item':
      return {
        start: '<li class="UList-Item"><p class="Paragraph Paragraph_size_m Paragraph_theme_default">',
        end: '</p></li>',
        nest: 'nest',
        nestStart: '<ul class="UList Post-List">',
        nestEnd: '</ul>'
      }
    case 'paragraph':
      return <Paragraph className={cnPost('Par')} size='m' theme='default' />
    case 'unstyled':
      return <Paragraph className={cnPost('Par')} size='m' theme='default' />
    default:
      return null
  }
}

const entityToHTML = (entity, originalText) => {
  if (entity.type === 'LINK') {
    return (
      <Link href={entity.data.url} theme='sapphire'>
        {originalText}
      </Link>
    )
  }
  return originalText
}

export const convertToHTML = (contentState) => (
  draftConvert({ blockToHTML, entityToHTML, styleToHTML })(contentState)
)
