import React from 'react'
import { withBemMod } from '@bem-react/core'

import Post from '../../../Post'
import { PageFooter } from '../../-Footer/Page-Footer'
import { PageHeader } from '../../-Header/Page-Header'
import { cnPage } from '../../Page'

const newBody = (Base, props) => {
  const { post, ...rest } = props

  return (
    <Base {...rest}>
      <PageHeader theme='default' activeLink='posts' />
      <main>
        <Post title={post.title}>
          {post.body}
        </Post>
      </main>
      <PageFooter />
    </Base>
  )
}

export const PageNamePost = withBemMod(
  cnPage(),
  { name: 'post' },
  newBody
)
