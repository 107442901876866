import React from 'react'

import Contact from '../../Contact'
import Contacts from '../../Contacts'
import Link from '../../Link'

import ivushkin from '../images/ivushkin.jpg'
import ivushkin2x from '../images/ivushkin@2x.jpg'
import pankratova from '../images/pankratova.jpg'
import pankratova2x from '../images/pankratova@2x.jpg'
import sinitsyn from '../images/sinitsyn.jpg'
import sinitsyn2x from '../images/sinitsyn@2x.jpg'

export const HomeControlCommission = () => (
  <Contacts
    desc={
      <React.Fragment>
        контролирует соблюдение устава, исполнение решений руководящих
        органов партии, а&nbsp;также контролирует финансовую, хозяйственную
        деятельность партии и&nbsp;её региональных отделений. {}
        <Link href='mailto:ckrk@lp-russia.org' theme='default'>
          ckrk@lp-russia.org
        </Link>
      </React.Fragment>
    }
    title='Центральная контрольно-ревизионная комиссия'
    withLabel>
    <Contact
      name='Алексей Ивушкин'
      photo={ivushkin}
      photo2x={ivushkin2x}
      position='Центральная контрольно-ревизионная комиссия' />
    <Contact
      name='Софья Панкратова'
      photo={pankratova}
      photo2x={pankratova2x}
      position='Центральная контрольно-ревизионная комиссия' />
    <Contact
      name='Иван Синицын'
      photo={sinitsyn}
      photo2x={sinitsyn2x}
      position='Центральная контрольно-ревизионная комиссия' />
  </Contacts>
)
