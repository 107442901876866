import React from 'react'

import Social from '../../Social'
import { cnFooter } from '../Footer'
import './Footer-SocialMedia.css'

export const FooterSocialMedia = () => (
  <ul className={cnFooter('SocialMedia')}>
    <li>
      <Social
        medium='telegram'
        size='m'
        uri='https://t.me/libertarian_party' />
    </li>
    <li>
      <Social
        medium='twitter'
        size='m'
        uri='https://twitter.com/lpr_tw' />
    </li>
    <li>
      <Social
        medium='youtube'
        size='m'
        uri='https://www.youtube.com/channel/UCKGIbaG0v6AcBB3J8TGtchg' />
    </li>
    <li>
      <Social
        medium='vk'
        size='m'
        uri='https://vk.com/lpr_russia' />
    </li>
  </ul>
)
