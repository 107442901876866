import React from 'react'

import { cnQuote } from '../Quote'
import './Quote-Text.css'

export const QuoteText = (props) => (
  <blockquote className={cnQuote('Text')}>
    {props.children}
  </blockquote>
)
