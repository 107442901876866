import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnSocial } from '../Social'

const YoutubeIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 42'>
    <g fill='none' fillRule='nonzero'>
      <rect fill='#f33' x='0' y='0' height='42' width='42' />
      <path fill='#fff'
        d='M 17,27.489
           l 10.645,-6.148
           L 17,15.194
           z' />
    </g>
  </svg>
)

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.icon = <YoutubeIcon />

  return <Base {...newProps} />
}

export const SocialMediumYoutube = withBemMod(
  cnSocial(),
  { medium: 'youtube' },
  newBody
)
