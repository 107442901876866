import React from 'react'

import { cnFooter } from '../Footer'
import './Footer-Layout.css'

export const FooterLayout = (props) => (
  <div className={cnFooter('Layout')}>
    {props.children}
  </div>
)
