import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnHeader } from '../../Header'
import { BurgerIcon } from './icons/Burger'

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.icon = <BurgerIcon color='#444' />

  return <Base {...newProps} />
}

export const HeaderBurgerThemeDefault = withBemMod(
  cnHeader('Burger'),
  { theme: 'default' },
  newBody
)
