import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnLogo } from '../../Logo'

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.topArc = '#e3672a'
  newProps.bottomArc = '#1a1919'
  newProps.bird = '#c5a664'

  return <Base {...newProps} />
}

export const LogoIconThemeDefault = withBemMod(
  cnLogo('Icon'),
  { theme: 'default' },
  newBody
)
