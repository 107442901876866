import React from 'react'
import { cn } from '@bem-react/classname'

import { ContactAbout } from './-About/Contact-About'
import { ContactBody } from './-Body/Contact-Body'
import { ContactDetails } from './-Details/Contact-Details'
import { ContactEmail } from './-Email/Contact-Email'
import { ContactName } from './-Name/Contact-Name'
import { ContactPhone } from './-Phone/Contact-Phone'
import { ContactPhoto } from './-Photo/Contact-Photo'
import { ContactTelegram } from './-Telegram/Contact-Telegram'
import './Contact.css'

export function cnContact (...args) {
  return cn('Contact')(...args)
}

export const Contact = (props) => (
  <div className={cnContact(null, [props.className])}>
    <ContactPhoto
      src={props.photo}
      srcSet={props.photo2x && `${props.photo2x} 2x`} />
    <ContactBody>
      <ContactName>{props.name}</ContactName>
      <ContactAbout>
        {props.position}
        {props.location}
      </ContactAbout>
      <ContactDetails>
        {props.telegram && <ContactTelegram>{props.telegram}</ContactTelegram>}
        {props.email && <ContactEmail>{props.email}</ContactEmail>}
        {props.phone && <ContactPhone>{props.phone}</ContactPhone>}
      </ContactDetails>
    </ContactBody>
  </div>
)
