import React from 'react'

import { cnHome } from '../Home'
import { HomePanelHidden } from './_hidden/Home-Panel_hidden'

const BaseHomePanel = (props) => (
  <div className={cnHome('Panel', [props.className])}>
    {props.children}
  </div>
)

export const HomePanel = HomePanelHidden(BaseHomePanel)
