import React from 'react'

import { cnHeader } from '../Header'
import { HeaderLink } from '../-Link/Header-Link'
import './Header-Actions.css'

export const HeaderActions = (props) => (
  <div className={cnHeader('Actions')}>
    <HeaderLink
      active={props.activeLink === 'join'}
      uri='/join'>
      Вступить
    </HeaderLink>
    <HeaderLink
      active={props.activeLink === 'donate'}
      uri='/donate'>
      Поддержать
    </HeaderLink>
  </div>
)
