import React from 'react'

import { cnContact } from '../Contact'
import './Contact-Details.css'

export const ContactDetails = (props) => {
  if (React.Children.toArray(props.children).length === 0) {
    return null
  }

  return (
    <address className={cnContact('Details')}>
      {React.Children.toArray(props.children).map((child, i) => (
        <React.Fragment key={i}>
          {child}
          <br />
        </React.Fragment>
      ))}
    </address>
  )
}
