import React from 'react'

import { cnContact } from '../Contact'
import './Contact-About.css'

export const ContactAbout = (props) => (
  <p className={cnContact('About')}>
    {React.Children.toArray(props.children).map((child) => (
      <span key={child}>
        {child}
      </span>
    ))}
  </p>
)
