import React from 'react'

import { cnFileDL } from '../FileDL.js'
import './FileDL-Preview.css'

export const FileDLPreview = ({ src }) => {
  if (!src) {
    return null
  }

  return <img className={cnFileDL('Preview')} src={src} alt='' />
}
