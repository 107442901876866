import React from 'react'

import { cnQuote } from '../Quote'
import './Quote-Source.css'

export const QuoteSource = (props) => (
  <figcaption className={cnQuote('Source')}>
    {props.children}
  </figcaption>
)
