import React from 'react'
import { convertFromRaw } from 'draft-js'

import { convertToHTML } from './convertToHTML'
import { cnPost } from '../Post'

export const PostContent = (props) => (
  <div
    className={cnPost('Content')}
    dangerouslySetInnerHTML={convert(props.children)} />
)

const convert = (rawDraftContentState) => (
  { __html: convertToHTML(convertFromRaw(rawDraftContentState)) }
)
