import React from 'react'

import { cnButton } from '../Button'

export const ButtonIcon = (props) => {
  if (React.Children.count(props.children) === 0) {
    return null
  }

  return (
    <span className={cnButton('Icon')}>
      {props.children}
    </span>
  )
}
