import React from 'react'
import { cn } from '@bem-react/classname'

import { FileDLCopyright } from './-Copyright/FileDL-Copyright.js'
import { FileDLLinks } from './-Links/FileDL-Links.js'
import { FileDLPreview } from './-Preview/FileDL-Preview.js'
import './FileDL.css'

export const cnFileDL = cn('FileDL')

export const FileDL = ({
  className,
  license,
  links,
  preview
}) => (
  <div className={cnFileDL(null, [className])}>
    <FileDLPreview src={preview} />
    <FileDLLinks links={links} />
    <FileDLCopyright license={license} />
  </div>
)
