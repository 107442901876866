import React from 'react'
import { cn } from '@bem-react/classname'

import './Bold.css'

export const cnBold = cn('Bold')

export const Bold = (props) => (
  <b className={cnBold(null, [props.className])}>
    {props.children}
  </b>
)
