import React from 'react'

import { cnPage } from '../Page'
import './Page-Text.css'

export const PageText = (props) => (
  <div className={cnPage('Text')}>
    {props.children}
  </div>
)
