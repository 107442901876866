import React from 'react'

import { cnHeader } from '../Header'
import { HeaderLinkActive } from './_active/Header-Link_active'
import './Header-Link.css'

export const BaseHeaderLink = (props) => (
  <a className={cnHeader('Link', [props.className])} href={props.uri}>
    {props.children}
  </a>
)

export const HeaderLink = HeaderLinkActive(BaseHeaderLink)
