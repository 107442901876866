import React from 'react'

import { cnIllust } from '../Illust'
import './Illust-Caption.css'

export const IllustCaption = (props) => (
  <figcaption className={cnIllust('Caption')}>
    {props.children}
  </figcaption>
)
