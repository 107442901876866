import React from 'react'

import Close from '../../Close'
import Link from '../../Link'
import Logo from '../../Logo'
import { cnMenu } from '../Menu'
import './Menu-Header.css'

export const MenuHeader = (props) => (
  <div className={cnMenu('Header')}>
    <Link href='/'>
      <Logo theme='default'>
        ЛПР
      </Logo>
    </Link>
    <Close onClick={props.onCloseClick} />
  </div>
)
