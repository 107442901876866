import React from 'react'

import Link from '../../Link/index.js'
import { cnFileDL } from '../FileDL.js'
import './FileDL-Link.css'

export const FileDLLink = ({
  filename,
  href,
  text
}) => (
  <Link
    className={cnFileDL('Link')}
    download={filename}
    href={href}
    theme='default'>
    {text}
  </Link>
)
