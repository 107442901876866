import React from 'react'

import { cnContacts } from '../Contacts'
import './Contacts-Item.css'

export const ContactsItem = (props) => (
  <div className={cnContacts('Item', [props.className])}>
    {props.children}
  </div>
)
