import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnSocial } from '../Social'

const VkIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 42'>
    <g fill='none' fillRule='nonzero'>
      <rect fill='#507299' x='0' y='0' height='42' width='42' />
      <path fill='#fff'
        d='M 22.426,25.471
           a 0.646 0.646 0 0 0 0.476,-0.204
             0.66 3.663 0 0 0 0.136,-0.408
           s 0,-1.342 0.612,-1.546
           c 0.611,-0.204 1.427,1.275 2.243,1.818
           a 1.87 1.87 0 0 0 1.156,0.34
           h 2.244
           s 1.155,0 0.611,-1.002
           a 8.346 8.346 0 0 0 -1.7,-1.887
           c -1.427,-1.275 -1.223,-1.07 0.477,-3.298
             1.02,-1.342 1.427,-2.158 1.36,-2.566
             -0.069,-0.408 -0.884,-0.272 -0.884,-0.272
           h -2.499
           a 0.918 0.918 0 0 0 -0.34,0
             0.527 0.527 0 0 0 -0.204,0.272
           c -0.262,0.677 -0.58,1.33 -0.952,1.955
             -1.155,1.886 -1.563,1.954 -1.7,1.886
             -0.135,-0.068 -0.34,-1.07 -0.34,-1.7
             0,-1.818 0.273,-2.566 -0.543,-2.77
           a 3.926 3.926 0 0 0 -1.377,-0.05
             5.949 5.949 0 0 0 -2.108,0.203
           c -0.272,0.136 -0.544,0.476 -0.408,0.476
             0.285,0.007 0.555,0.13 0.748,0.34
             0.185,0.35 0.279,0.742 0.272,1.139
             0,0 0.136,2.158 -0.34,2.43
             -0.476,0.272 -0.816,-0.204 -1.835,-1.886
           a 10.997 10.997 0 0 1 -0.884,-1.82
             0.527 0.527 0 0 0 -0.187,-0.271
             0.68 0.68 0 0 0 -0.408,-0.136
           h -2.448
           a 0.85 0.85 0 0 0 -0.544,0.136
           c -0.136,0.136 0,0.408 0,0.408
           a 27.195 27.195 0 0 0 4.08,6.544
             5.864 5.864 0 0 0 4.215,1.886
           l 1.07,-0.017
           z' />
    </g>
  </svg>
)

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.icon = <VkIcon />

  return <Base {...newProps} />
}

export const SocialMediumVk = withBemMod(
  cnSocial(),
  { medium: 'vk' },
  newBody
)
