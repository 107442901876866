import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnButton } from '../Button'
import { TelegramIcon } from './icons/Telegram'
import './Button_theme_telegram.css'

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.icon = <TelegramIcon />

  return <Base {...newProps} />
}

export const ButtonThemeTelegram = withBemMod(
  cnButton(),
  { theme: 'telegram' },
  newBody
)
