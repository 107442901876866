import { compose } from '@bem-react/core'

import { Logo as BaseLogo } from './Logo'
import { LogoThemeDefault } from './_theme/Logo_theme_default'
import { LogoThemeNegative } from './_theme/Logo_theme_negative'

const Logo = compose(
  LogoThemeDefault,
  LogoThemeNegative
)(BaseLogo)

export default Logo
