import Picker from 'react-datetime-picker'
import React, { useState } from 'react'
import { cn } from '@bem-react/classname'

const cnDateTimePicker = cn('DateTimePicker')

export const DateTimePicker = (props) => {
  const [value, onChange] = useState(new Date(props.value))

  return (
    <div className={cnDateTimePicker(null, [props.className])}>
      <Picker
        calendarIcon={null}
        clearIcon={null}
        format='yyyy-MM-dd HH:mm'
        name={props.name}
        onChange={onChange}
        required={props.required}
        showLeadingZeros
        value={value}
      />
    </div>
  )
}
