import { compose } from '@bem-react/core'

import { Button as BaseButton } from './Button'
import { ButtonThemeDefault } from './_theme/Button_theme_default'
import { ButtonThemeEmerald } from './_theme/Button_theme_emerald'
import { ButtonThemePrimary } from './_theme/Button_theme_primary'
import { ButtonThemeSecondary } from './_theme/Button_theme_secondary'
import { ButtonThemeTelegram } from './_theme/Button_theme_telegram'
import { ButtonTypeDisabled } from './_type/Button_type_disabled'
import { ButtonTypeLink } from './_type/Button_type_link'

const Button = compose(
  ButtonThemeDefault,
  ButtonThemeEmerald,
  ButtonThemePrimary,
  ButtonThemeSecondary,
  ButtonThemeTelegram,
  ButtonTypeDisabled,
  ButtonTypeLink
)(BaseButton)

export default Button
