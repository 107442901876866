import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnSocial } from '../Social'

const InstagramIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 42'>
    <defs>
      <linearGradient id='linear' x1='50%' x2='50%' y1='0%' y2='96.71%'>
        <stop offset='0%' stopColor='#5974f6' />
        <stop offset='100%' stopColor='#f24a75' />
      </linearGradient>
      <radialGradient id='radial'
        cx='22.169%'
        cy='100%'
        fx='22.169%'
        fy='100%'
        r='75.942%'>
        <stop offset='0%' stopColor='#ffc85a' />
        <stop offset='100%' stopColor='#f24a75' stopOpacity='0.104' />
      </radialGradient>
    </defs>
    <g fill='none' fillRule='nonzero'>
      <rect fill='url(#linear)' x='0' y='0' width='42' height='42' />
      <rect fill='url(#radial)' x='0' y='0' width='42' height='42' />
      <path fill='#fff'
        d='M 12,17.199
           A 5.199 5.199 0 0 1 17.199,12
           H 24.8
           A 5.199 5.199 0 0 1 30,17.199
           V 24.8
           A 5.199 5.199 0 0 1 24.801,30
           H 17.2
           A 5.199 5.199 0 0 1 12,24.801
           V 17.2
           z
           m 1.63,0.155
           v 7.292
           a 3.725 3.725 0 0 0 3.724,3.725
           h 7.292
           a 3.725 3.725 0 0 0 3.725,-3.725
           V 17.354
           a 3.725 3.725 0 0 0 -3.725,-3.725
           H 17.354
           a 3.725 3.725 0 0 0 -3.725,3.725
           z
           M 20.972,25.709
           a 4.737 4.737 0 1 1 0,-9.474
             4.737 4.737 0 0 1 0,9.474
           z
           m 0,-1.686
           a 3.05 3.05 0 1 0 0,-6.102
             3.05 3.05 0 0 0 0,6.102
           z' />
      <circle fill='#fff' cx='25.263' cy='15.789' r='1' />
    </g>
  </svg>
)

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.icon = <InstagramIcon />

  return <Base {...newProps} />
}

export const SocialMediumInstagram = withBemMod(
  cnSocial(),
  { medium: 'instagram' },
  newBody
)
