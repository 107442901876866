import React from 'react'

import { cnContact } from '../Contact'
import './Contact-Link.css'

export const ContactLink = (props) => (
  <a className={cnContact('Link', [props.className])} href={props.href}>
    {props.children}
  </a>
)
