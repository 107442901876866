import React from 'react'

import { ContactLink } from '../-Link/Contact-Link'
import { cnContact } from '../Contact'

export const ContactEmail = (props) => (
  <ContactLink
    className={cnContact('Email')}
    href={`mailto:${props.children}`}>
    {props.children}
  </ContactLink>
)
