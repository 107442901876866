import React from 'react'
import { cn } from '@bem-react/classname'

import { OListItem } from './-Item/OList-Item'
import './OList.css'

export const cnOList = cn('OList')

export const OList = (props) => (
  <ol className={cnOList(null, [props.className])}>
    {React.Children.toArray(props.children).map((child, i) => (
      <OListItem key={i}>
        {child}
      </OListItem>
    ))}
  </ol>
)
