import React from 'react'

import Heading from '../../Heading'
import { cnFooter } from '../Footer'
import './Footer-Heading.css'

export const FooterHeading = (props) => (
  <Heading className={cnFooter('Heading')} size='s'>
    {props.children}
  </Heading>
)
