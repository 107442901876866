import { compose } from '@bem-react/core'

import { Page as BasePage } from './Page'
import {
  PageNameLibertarianism
} from './_name/_libertarianism/Page_name_libertarianism'
import { PageNameParty } from './_name/_party/Page_name_party'
import { PageNamePlatform } from './_name/_platform/Page_name_platform'
import { PageNamePost } from './_name/_post/Page_name_post'
import { PageNamePress } from './_name/_press/Page_name_press'
import { PageNameSuccess } from './_name/_success/Page_name_success'

const Page = compose(
  PageNameLibertarianism,
  PageNameParty,
  PageNamePlatform,
  PageNamePost,
  PageNamePress,
  PageNameSuccess
)(BasePage)

export default Page
