import React from 'react'
import { cn } from '@bem-react/classname'

import { IllustCaption } from './-Caption/Illust-Caption'
import { IllustPicture } from './-Picture/Illust-Picture'
import './Illust.css'

export const cnIllust = cn('Illust')

export const Illust = (props) => (
  <figure className={cnIllust(null, [props.className])}>
    <IllustPicture alt={props.alt} uri={props.uri} />
    <IllustCaption>{props.children}</IllustCaption>
  </figure>
)
