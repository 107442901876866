import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnButton } from '../Button'
import { ButtonIcon } from '../-Icon/Button-Icon'
import { ButtonText } from '../-Text/Button-Text'
import './Button_type_link.css'

const newBody = (Base, props) => (
  <a className={props.className} href={props.uri}>
    <ButtonIcon>{props.icon}</ButtonIcon>
    <ButtonText>{props.children}</ButtonText>
  </a>
)

export const ButtonTypeLink = withBemMod(cnButton(), { type: 'link' }, newBody)
