import React from 'react'

import { cnMenu } from '../Menu'
import { MenuItem } from '../-Item/Menu-Item'
import './Menu-List.css'

export const MenuList = (props) => (
  <ul className={cnMenu('List')}>
    <MenuItem
      active={props.activeItem === 'posts'}
      uri='/posts'>
      Публикации
    </MenuItem>
    <MenuItem
      active={props.activeItem === 'party'}
      uri='/'>
      Партия
    </MenuItem>
    <MenuItem
      active={props.activeItem === 'libertarianism'}
      uri='/libertarianism'>
      Либертарианство
    </MenuItem>
    <MenuItem
      active={props.activeItem === 'join'}
      uri='/join'>
      Вступить
    </MenuItem>
    <MenuItem
      active={props.activeItem === 'donate'}
      uri='/donate'>
      Поддержать
    </MenuItem>
  </ul>
)
