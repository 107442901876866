import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnSocial } from '../Social'

const FacebookIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 42'>
    <g fill='none' fillRule='nonzero'>
      <rect fill='#39579a' x='0' y='0' height='42' width='42' />
      <path fill='#fff'
        d='M 22,17.34
           v 0.663
           h 3
           l -0.23,3
           H 22
           V 29
           h -3.003
           v -7.997
           H 17
           v -3
           h 2
           l -0.003,-1.666
           s 0.34,-3.668 3.003,-3.937
           h 3
           V 15
           h -1.337
           c -0.2,0 -1.663,-0.29 -1.663,2.34
           z' />
    </g>
  </svg>
)

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.icon = <FacebookIcon />

  return <Base {...newProps} />
}

export const SocialMediumFacebook = withBemMod(
  cnSocial(),
  { medium: 'facebook' },
  newBody
)
