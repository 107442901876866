import React from 'react'

import Contacts from '../../Contacts'
import Contact from '../../Contact'
import Link from '../../Link'

import belov from '../images/belov.jpg'
import belov2x from '../images/belov@2x.jpg'
import loshak from '../images/loshak.jpg'
import loshak2x from '../images/loshak@2x.jpg'
import matsapulina from '../images/matsapulina.jpg'
import matsapulina2x from '../images/matsapulina@2x.jpg'
import mozgov from '../images/mozgov.jpg'
import mozgov2x from '../images/mozgov@2x.jpg'

export const HomeFederalCommittee = () => (
  <Contacts
    desc={
      <React.Fragment>
        основной руководящий орган, управляющий партией между съездами. {}
        <Link href='mailto:fk@lp-russia.org' theme='default'>
          fk@lp-russia.org
        </Link>
      </React.Fragment>
    }
    title='Федеральный комитет'
    withLabel>
    <Contact
      email='mm@lp-russia.org'
      name='Марина Мацапулина'
      photo={matsapulina}
      photo2x={matsapulina2x}
      position='Председатель'
      telegram='spacehead' />
    <Contact
      email='mozgovty@lp-russia.org'
      name='Артём Мозгов'
      photo={mozgov}
      photo2x={mozgov2x}
      position='Зам. председателя'
      telegram='mozgovty' />
    <Contact
      email='belov@lp-russia.org'
      name='Георгий Белов'
      photo={belov}
      photo2x={belov2x}
      position='Федеральный комитет' />
    <Contact
      email='loshak@lp-russia.org'
      name='Елизавета Лошак'
      photo={loshak}
      photo2x={loshak2x}
      position='Федеральный комитет' />
  </Contacts>
)
