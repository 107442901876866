import React from 'react'
import { cn } from '@bem-react/classname'

import './Close.css'

const cnClose = cn('Close')

export const Close = (props) => (
  <button className={cnClose()} onClick={props.onClick}>
    <svg xmlns='http://www.w3.org/2000/svg'
      height='12'
      width='12'
      viewBox='0 0 12 12'>
      <g fill='none' fillRule='evenodd'>
        <path stroke='#444'
          strokeLinecap='square'
          d='M 11.5,0.5
             L 0.893,11.107
             M 0.5,0.5
             l 10.607,10.607' />
      </g>
    </svg>
  </button>
)
