import React from 'react'
import { cn } from '@bem-react/classname'

import Heading from '../Heading'
import { PostContent } from './-Content/Post-Content'
import './Post.css'

export const cnPost = cn('Post')

export const Post = (props) => (
  <article className={cnPost(null, [props.className])}>
    <div className={cnPost('Inner')}>
      <Heading className={cnPost('Head')} size='l' tag='h1'>
        {props.title}
      </Heading>
      <PostContent>
        {JSON.parse(props.children)}
      </PostContent>
    </div>
  </article>
)
