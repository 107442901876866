import { compose } from '@bem-react/core'

import { Link as BaseLink } from './Link'
import { LinkThemeBaikal } from './_theme/_baikal/Link_theme_baikal'
import { LinkThemeDefault } from './_theme/_default/Link_theme_default'
import { LinkThemeSapphire } from './_theme/_sapphire/Link_theme_sapphire'

const Link = compose(
  LinkThemeBaikal,
  LinkThemeDefault,
  LinkThemeSapphire
)(BaseLink)

export default Link
