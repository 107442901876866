import React from 'react'

import { cnPage } from '../Page'
import './Page-Content.css'

export const PageContent = (props) => {
  const Tag = props.tag || 'div'

  return (
    <Tag className={cnPage('Content')}>
      {props.children}
    </Tag>
  )
}
