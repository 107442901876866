import React from 'react'

import { cnFooter } from '../Footer'
import { FooterChatLink } from '../-ChatLink/Footer-ChatLink'
import { FooterParagraph } from '../-Paragraph/Footer-Paragraph'
import { FooterHeading } from '../-Heading/Footer-Heading'
import './Footer-Main.css'

export const FooterMain = () => (
  <div className={cnFooter('Main')}>
    <FooterHeading>Либертарианский публичный чат</FooterHeading>
    <FooterChatLink mobile>Либертарианский публичный чат</FooterChatLink>
    <FooterParagraph>
      Чат, в&nbsp;котором члены ЛПР могут ответить на&nbsp;ваши вопросы
      на&nbsp;тему современной политики, экономики, общества
      с&nbsp;либертарианской позиции. Здесь&nbsp;же можно спросить
      о&nbsp;самой&nbsp;партии.
    </FooterParagraph>
    <FooterChatLink desktop>Открыть в&nbsp;телеграме</FooterChatLink>
  </div>
)
