import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnLogo } from '../Logo'
import './Logo_theme_default.css'

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.iconTheme = 'default'

  return <Base {...newProps} />
}

export const LogoThemeDefault = withBemMod(
  cnLogo(),
  { theme: 'default' },
  newBody
)
