import React from 'react'
import { withBemMod } from '@bem-react/core'

import Heading from '../../../Heading'
import Link from '../../../Link'
import OList from '../../../OList'
import Paragraph from '../../../Paragraph'
import Quote from '../../../Quote'
import UList from '../../../UList'

import { PageContent } from '../../-Content/Page-Content'
import { PageFooter } from '../../-Footer/Page-Footer'
import { PageHeader } from '../../-Header/Page-Header'
import { PageText } from '../../-Text/Page-Text'
import { cnPage } from '../../Page'

import nolanChart from './images/nolan_chart.svg'
import nolanChartMobile from './images/nolan_chart_mobile.svg'
import politicalSpectrum from './images/political_spectrum.svg'
import politicalSpectrumMobile from './images/political_spectrum_mobile.svg'
import publicSpending from './images/public_spending.svg'
import publicSpendingMobile from './images/public_spending_mobile.svg'
import './Page_name_libertarianism.css'

const newBody = (Base, props) => (
  <Base {...props}>
    <div className={cnPage('Banner')}>
      <PageHeader theme='negative' activeLink='libertarianism' />
      <PageContent>
        <PageText>
          <h1 className={cnPage('Head')}>
          Либертарианство
          </h1>
          <p className={cnPage('Lead')}>
            {`Объясняем наши идеи от общего к частному.
            Чтение займёт 40 минут.`}
          </p>
          <ol className={cnPage('TOC')}>
            <li>
              <Link href='#individual-sovereignty'>
              Индивидуальный суверенитет
              </Link>
            </li>
            <li>
              <Link href='#nap'>
              Принцип ненападения
              </Link>
            </li>
            <li>
              <Link href='#minarchism-and-ancap'>
              Минархизм и&nbsp;анархо-капитализм
              </Link>
            </li>
            <li>
              <Link href='#liberalism'>
              Классический и&nbsp;современный либерализм
              </Link>
            </li>
            <li>
              <Link href='#left-and-right'>
              «Левое» и&nbsp;«правое»
              </Link>
            </li>
            <li>
              <Link href='#state'>
              Государство
              </Link>
            </li>
            <li>
              <Link href='#free-market-myths'>
              Мифы о&nbsp;рынке
              </Link>
            </li>
            <li>
              <Link href='#religion'>
              Религия
              </Link>
            </li>
            <li>
              <Link href='#ethics'>
              Этика
              </Link>
            </li>
            <li>
              <Link href='#disputes'>
              Спорные вопросы
              </Link>
            </li>
            <li>
              <Link href='#nolan-chart'>
              Диаграмма Нолана
              </Link>
            </li>
          </ol>
        </PageText>
      </PageContent>
    </div>
    <PageContent tag='main'>
      <PageText>
        <Heading
          className={cnPage('Sub')}
          id='individual-sovereignty'
          size='l'
          tag='h2'>
          Индивидуальный суверенитет
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          У большинства людей мысль, что каждый человек принадлежит
          исключительно себе, не вызывает удивления. Это утверждение кажется
          естественным и обычно не оспаривается. Но действительно ли мы
          правильно понимаем, что такое индивидуальный суверенитет и что он
          нам дает? Что это вообще значит — принадлежать самому себе?
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Впервые концепцию самопринадлежности описал английский философ Джон
          Локк, чьи идеи оказали огромное влияние на развитие политической
          философии. В «Двух трактатах о правлении» он писал, что у каждого
          человека есть право собственности на свою личность, в том числе право
          выбора, кем стать и чем заниматься. Свобода, по Локку, это не
          состояние, в котором «каждый делает, что пожелает», — это свобода
          человека распоряжаться своей личностью, действиями и собственностью,
          «не подвергаться деспотической воле другого, а свободно следовать
          своей воле».
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Допустим, вам принадлежит какая-либо вещь — скажем, одежда, машина,
          дом или пакет акций. Очевидно, это ваша собственность, которой вы
          можете распоряжаться, как вам вздумается — точно так же, как вы
          распоряжаетесь самим собой. Индивидуальный суверенитет значит, что
          только вы можете решать, как вам распоряжаться собой и своей
          собственностью. Другие люди не имеют права использовать вашу
          собственность без вашего на то разрешения либо принуждать вас делать
          с ней то, чего вы не хотите.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Обычно государство {}
          <Link href='#disputes' theme='sapphire'>
            закрепляет это право
          </Link>
          {} в&nbsp;Конституции и&nbsp;кодексах законов. Широко разделяемое
          представление о&nbsp;справедливости состоит в&nbsp;том, что
          ограничения в&nbsp;правах допустимы только в&nbsp;отношении тех, кто
          сам нарушил права других. Но&nbsp;государство часто ограничивает вас
          в&nbsp;ваших действиях без такой предпосылки, нарушая тем самым
          индивидуальный суверенитет.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Так, некоторые законы предусматривают наказание за преступления без
          жертвы (например, употребление алкоголя или наркотиков, проявление
          или защита гомосексуальности, проституция, действия, описанные в
          статье 282 УК РФ) или лишают людей права распоряжаться имуществом
          (копирайт запрещает вам пользоваться ручкой, бумагой, компьютером,
          телефоном — чем угодно — для создания копий некоторой информации),
          или даже лишают людей права распоряжаться своим телом (запреты на
          эвтаназию, аборт, наказание за употребление наркотиков).
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В либертарианстве принцип индивидуального суверенитета наряду с NAP
          является одним из самых главных. Репрессивный государственный аппарат
          не должен ограничивать право собственности человека на самого себя.
          Если государство вообще должно существовать, то его задачей не может
          быть ничто иное, кроме защиты прав собственности и самопринадлежности
          человека.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='nap'
          size='l'
          tag='h2'>
          Принцип ненападения в&nbsp;либертарианстве
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Одной из основополагающих идей либертарианства является принцип
          ненападения (НАП, он же принцип неагрессии или NAP, non-aggression
          principle) — этическая позиция, признающая агрессивное насилие
          нелегитимным. Сама по себе позиция имеет очень древние корни, вариант
          такой этики можно проследить в мировых религиях и многих философских
          учениях. Но именно в XX веке концепция НАП получает такое название
          и становится ключевой для либертарианства.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В книге «Либертарианство: основы» Дэвид Боуз писал:
        </Paragraph>
      </PageText>
      <Quote className={cnPage('Quote')}>
        С&nbsp;точки зрения либертарианства, все отношения между людьми должны
        быть добровольными; единственные действия, которые должны быть
        запрещены законом,&nbsp;— это… применение силы против тех, кто сам
        не&nbsp;применял силу.
      </Quote>
      <PageText>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Это емкое и точное определение либертарианства.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          НАП иногда отождествляют с пацифизмом, что неверно. Пацифисты не
          приемлют никакого насилия. НАП допускает насилие в случае
          самообороны — неагрессивное, защитное насилие в ответ на агрессию.
          В рамках НАП жертва имеет право любыми средствами защищаться от
          агрессора.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Запрет на агрессивное насилие распространяется не только на само
          нападение, но и на подготовку, а также на угрозу нападения. Соблюдение
          НАП договаривающимися сторонами гарантирует безопасность частной
          собственности, личности и защиту от последствий в случае «преступления
          без жертвы».
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Если с точки зрения НАП рассмотреть государство, выяснится, что многие
          его элементы (налогообложение, призыв на воинскую службу,
          регулирование добровольного обмена и т.д.) нарушают принцип
          неагрессии. Последовательное применение НАП позволит на практике
          запретить подобные элементы государственного насилия. В пределе, по
          мнению некоторых либертарианцев, государство может быть вообще
          отменено: люди могут защититься от угрозы посредством прямых договоров
          с частными страховыми компаниями, с помощью добровольной полиции или
          же полностью личной самообороны.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='minarchism-and-ancap'
          size='l'
          tag='h2'>
          Минархизм и&nbsp;анархо-капитализм
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Под общим термином «либертарианство» объединяются и уживаются два
          течения: анархо-капитализм и минархизм.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Анархо-капитализм (также: анкап) выступает за ликвидацию государства
          в пользу индивидуального суверенитета в условиях свободного рынка.
          Это значит, что все функции обеспечения правопорядка будут
          предоставлены добровольно финансируемыми конкурентами, например,
          частными охранными агентствами. Экономическая и личная деятельность
          будут регулироваться с помощью свободного рынка, частного права и
          местных законов. Налоги при анкапе будут полностью устранены.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Минархизм, в отличие от анархо-капитализма, не исключает
          существования государства, но расценивает его как «неизбежное зло».
          Минархисты находят неизбежным и налогообложение, но считают, что все
          собранные налоги должны расходоваться только на строго обозначенную
          и ограниченную судебную, правоохранительную, оборонную и общую
          административную деятельность государства.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Несмотря на разницу в подходе к вопросу о допустимых пределах
          государства, либертарианцы двух направлений, как правило, способны
          договориться и прийти к схожему решению большинства вопросов.
          Моральные позиции объединяют их гораздо больше, чем разъединяют —
          всем либертарианцам свойственны поддержка индивидуального
          суверенитета, запрет на агрессивное насилие, принципиальное неприятие
          насилия против людей и их имущества.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='liberalism'
          size='l'
          tag='h2'>
          Либертарианство, классический либерализм и&nbsp;современный либерализм
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Кто такие либералы? В XIX веке так называли себя сторонники
          ограничения власти самодержца. В конце 80-х либералами были те, кто
          вышел против советского режима, на протяжении 70 лет державшего целые
          народы в нищете и рабстве (единства в рядах либеральной оппозиции не
          было, люди были согласны лишь в одном — в необходимости отказа от
          советского строя и социализма).
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Сейчас на Западе и особенно в Европе либералами считаются люди левых
          взглядов, чей «либерализм» можно описать как смесь культурного
          марксизма в политике и рынка, ограниченного государственными
          регуляциями, в экономике. Это политическое течение обладает большим
          влиянием на политику властей в странах, считающихся оплотом
          «либерализма».
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Современные «левые либералы» считают, что государство может и должно
          активно вмешиваться в жизни людей. Они выступают за цензуру, изъятие
          и ограничение возможностей распоряжения собственностью «для нужд
          общества», за высокие налоги, государственные системы здравоохранения,
          образования и социального обеспечения, антимонопольные службы и за
          многие другие экономические и социальные регуляции.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          На протяжении многих лет сторонники таких идей называли себя
          либералами, искажая представления людей о сущности свободы, из-за
          чего многие, к сожалению, забывают про классический либерализм —
          идеологию, среди всех либеральных движений наиболее близкую принципам
          свободы. Всё началось с Первой Мировой войны 1914-18, с начала которой
          государства начали активно наращивать свое присутствие в экономике.
          Тогда это называлось «временными мерами», но после войны количество
          экономических регуляций не только сохранилось, но и постепенно
          увеличивалось, особенно после Великой депрессии в США 1929-39, которую
          мейнстримные экономисты того времени обосновывали отсутствием
          государственного вмешательства (в реальности всё наоборот) и очередной
          мировой войны.
        </Paragraph>
      </PageText>
      <object
        className={cnPage('SpendingMobile')}
        data={publicSpendingMobile}
        type='image/svg+xml' />
      <object
        className={cnPage('Spending')}
        data={publicSpending}
        type='image/svg+xml' />
      <PageText>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Настоящие либералы, классические, не приемлют столь серьезного
          вмешательства в жизнь индивида. Их взгляды разнятся, ведь классический
          либерализм — это не догматичное политическое течение, но в основе их
          представлений об устройстве общества лежит один основной принцип:
          роль государства должна ограничиваться защитой частной собственности,
          мира, естественных прав и свобод людей.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Все классические либералы выступают за контроль армии, судебной
          системы, сбор налогов (естественно, малого уровня). Некоторые из них
          также за интеллектуальную собственность, наличие ЦБ и государственное
          лицензирование продукции, а в очень редких случаях поддерживающие эту
          идеологию выступают и за государственное образование.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианство (по крайней мере, минархизм) и классический
          либерализм — родственные политические течения, имеющие одну
          и ту же цель: установление общества, строящегося на
          принципах свободного рынка и максимально возможного
          невмешательства государства в жизни людей. Различие в том,
          что классические либералы не базируют свою платформу на
          принципе неагрессии и правах собственности — и потому могут
          выступать за те полномочия государства, которые они видят
          утилитарно необходимыми или этически оправданными.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='left-and-right'
          size='l'
          tag='h2'>
          Так называемое «левое» и&nbsp;«правое» в&nbsp;либертарианстве
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Деление политических движений на «левые» и «правые» чаще встречается
          в США или Европе, в России эта классификация не так популярна. Однако
          сам вопрос поучителен для либертарианца, где бы тот ни находился.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Если обратиться к&nbsp;популярным источникам, то&nbsp;консенсус
          среди них будет примерно такой:
        </Paragraph>
        <UList className={cnPage('List')}>
          <Paragraph size='m' theme='default'>
            «левые» выступают за&nbsp;регулирование бизнеса, за&nbsp;экологию,
            за&nbsp;легализацию марихуаны, за&nbsp;высокие налоги, против
            интересов банков, за&nbsp;субсидирование культуры
            и&nbsp;образования, за&nbsp;ограничение доступа граждан
            к&nbsp;оружию;
          </Paragraph>
          <Paragraph size='m' theme='default'>
            «правые» выступают против легализации марихуаны, за&nbsp;религию
            (в&nbsp;том или ином смысле), против регулирования бизнеса,
            за&nbsp;низкие налоги, против геев (в&nbsp;том или ином смысле),
            против мигрантов, за&nbsp;активное субсидирование армии.
          </Paragraph>
        </UList>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В такой классификации не видно особой логики: с чего бы, например,
          экоактивисту вдруг не пристало иметь антииммигрантских убеждений?
          Почему деление на «левых» и «правых» прошло именно так?
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Есть одно правдоподобное объяснение: человеку неинтересно разбираться
          в&nbsp;различиях между политическими взглядами, не&nbsp;похожими
          на&nbsp;его собственные. С&nbsp;точки зрения большинства людей
          политический спектр выглядит так:
        </Paragraph>
        <img
          alt='Отрезок, в левой части которого надпись «мои взгляды», в центре
            надпись «умеренно не мои взгляды», в правой части — «радикально
            не мои взгляды».'
          className={cnPage('Spectrum')}
          src={politicalSpectrum}
          srcSet={
            `${politicalSpectrumMobile} 580w, ${politicalSpectrum} 700w`
          } />
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Это отрезок. Поэтому на него легко повесить два ярлыка: синие-красные,
          белые-красные, западники-славянофилы… левые-правые. Если теперь
          наиболее острые вопросы раскидать по двум фронтам, то и получится та
          самая «классификация» из открытых источников, где люди говорят
          «лево-право», а подразумевают чаще всего «наши-не наши». Групповое
          мышление порождает даже у политически активных и самостоятельных людей
          ошибку подтверждения, что и приводит к абсурдным утверждениям от
          «если за экологию, то не против геев» и до «если за экологию и против
          геев, то центрист и бесхребетный соглашатель».
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          При этом разновидностей политических взглядов много. Если всех, с кем
          не согласен, отправлять «на тот конец отрезка», то там расположится
          непропорционально много сильно отличающихся мнений и людей, их
          придерживающихся, которые будут казаться одним миром мазаными.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Поэтому политика в целом поляризует. У каждого есть с чем не
          согласиться с каждым, а чем больше функций вы и ваш оппонент в таком
          споре видите за государством, тем больше поводов спорить. (Это
          неспроста, так как вопрос о полномочиях государства — это вопрос о
          том, к кому можно {}
          <Link href='#ethics' theme='sapphire'>
            применять силу
          </Link>
          ).
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианство может собрать в себе как «правых», так и «левых»,
          как «белых», так и «красных», как «либералов», так и «консерваторов»,
          как «западников», так и «славянофилов» — просто потому, что
          либертарианцы считают, что государство не должно делать слишком
          многого. У людей, которые сходятся в этой мысли, заведомо меньше,
          чем у остальных, поводов спорить друг с другом о политике, меньше
          споров о целях и еще меньше — о методах (любые насильственные методы
          быстро получают у либертарианца низкий рейтинг).
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Люди, которые не хотят или не могут расстаться с
          классификацией на левых и правых, либертарианцев относят
          скорее к правым. Скажем, уточнение «левый либертарианец»
          встречается в разы чаще, чем «правый либертарианец». Этому
          есть простое объяснение: один из признаков «левых» —
          недоверие к частной собственности в целом и к деньгам в
          частности; недоверие сильное, вплоть до предложений
          полностью уничтожить оба эти института. Но либертарианцы,
          во-первых, строят всю аргументацию вокруг частной
          собственности, поэтому для них неприемлемо хоть
          сколько-нибудь скептическое (в том числе «левое») отношение
          к ней; во-вторых, либертарианцы не считают материальное
          неравенство разновидностью неравенства политического — а
          такое отношение к деньгам, в свою очередь, неприемлемо для
          «левых».
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Дихотомия «левые-правые» демонстрирует изрядную стабильность.
          Поляризация выгодна многим: радикалы заинтересованы в том, чтобы
          оставаться радикалами — это часть их политической идентичности. Их
          умеренные оппоненты тоже заинтересованы в том, чтобы радикалы
          оставались радикалами — маргинальными и разобщенными. Бессмысленность
          и стабильность этой классификации можно наглядно наблюдать на примере
          двухпартийной системы США. Партий стабильно две, хотя их идеологии
          (и даже названия) не стабильны во времени. Наиболее рефлексирующая
          часть населения понимает, что выбор между ними искусственный.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Так оно и есть. «Являются ли либертарианцы левыми или правыми?» —
          вопрос малоосмысленный. На подобные вопросы лучше не отвечать.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='state'
          size='l'
          tag='h2'>
          Что такое государство?
        </Heading>
      </PageText>
      <Quote className={cnPage('Quote')} source='Фредерик Бастиа'>
        Государство — это великая фикция, с помощью которой каждый пытается
        жить за счет всех остальных.
      </Quote>
      <PageText>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Хотя современное государство сложилось относительно недавно, его
          существование и необходимость чаще всего принимаются людьми как
          неоспоримая данность. К счастью, с этой «данностью» можно бороться.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          По определению Макса Вебера, государство — это организация, которая
          владеет монополией на легитимное физическое насилие. Большинство
          людей скажут, что государство защищает их интересы, но при этом на
          практике будут ругать и неэффективность бюрократии, и
          коррумпированность должностных лиц, сетуя, что власть развращает тех,
          кому достается.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Все эти претензии справедливы, и либертарианцы — единственные, кто
          воспринимает их всерьез и считает эти проблемы устранимыми и
          решаемыми на системном уровне.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Действительно, государство неэффективно, коррумпировано и осуществляет
          репрессии, хотя граждане ожидают, что оно будет защищать их права.
          Все эти факты взаимосвязаны. Государство состоит из людей,
          которые тоже совершают ошибки. Хотя цена их ошибок выше, убытки
          от этих ошибок терпят все граждане. Это способствует коррупции
          и привлекает к государственной деятельности людей, не стесняющихся
          использовать ее для своей личной выгоды. Для защиты своего положения
          они, конечно, предпочтут не защищать права остальных, а проводить
          репрессии. Степень зверства, с которой это всё происходит, зависит
          от того, насколько удачно выстроена система сдержек и противовесов.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианцы считают, что роль государства в&nbsp;жизни общества
          должна быть минимизирована, а&nbsp;{}
          <Link href='#minarchism-and-ancap' theme='sapphire'>
            некоторые из&nbsp;них
          </Link>
          {} допускают, что его существование и&nbsp;вовсе необязательно.
        </Paragraph>
      </PageText>
      <Quote
        className={cnPage('Quote')}
        source='Павел Усанов. «Наука о богатстве»'>
        Для того чтобы общество могло существовать, нам несомненно нужны
        определенные нормы, но их источниками совсем не обязательно должно
        быть государство. Вполне возможно использование частных норм, которые
        в процессе конкуренции будут развиваться гораздо более эффективно,
        чем нормы, которые устанавливаются централизованно.
      </Quote>
      <PageText>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Существование государства поддерживается налогообложением. Редко
          кому нравится, как государство тратит собранные средства, но обычно
          налоги воспринимаются как неизбежный «общественный договор». Однако
          либертарианцы принципиально выступают против налогообложения,
          выдвигая как этические (налоги взимаются недобровольно, под угрозой
          применения насилия, и сами по себе являются таким образом насилием
          сродни грабежу; никто не может делегировать государству полномочия
          собирать налоги, так как ни у кого из людей нет полномочий насильно
          собирать деньги с других людей), так и рыночные доводы
          (налогообложение приносит прибыль в том числе и тем, кто работает
          плохо). Заменой современной налоговой системе могут служить
          добровольные сборы на оплату тех или иных услуг, предоставляемых
          государством или же полностью на частной основе.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='free-market-myths'
          size='l'
          tag='h2'>
          Несколько мифов о&nbsp;рынке. Рыночек порешает, или почему
          естественный спонтанный порядок лучше, чем государство
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Среди людей, не слишком интересующихся общественными науками, ходит
          огромное количество мифов о рыночной экономике, которые государство
          успешно прививает еще в школе. Рынок обвиняют во всех проблемах
          человечества — от бедности до войн. Достаточно оценить эти претензии
          с точки зрения логики, чтобы убедиться в их ложности.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          «Свободный рынок приводит к&nbsp;войнам»
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Пожалуй, это одно из самых популярных обвинений. Согласно мифу,
          «злые капиталисты» наживаются за счет войн, ведя миллионы людей на
          верную смерть.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          На самом деле всё абсолютно наоборот. Предпринимателям войны приносят
          одни убытки: население беднеет, уменьшается спрос на многие товары и
          услуги, происходит разрыв отношений с торговыми партнерами за
          границей и перебои в поставках ресурсов. Частная предпринимательская
          инициатива и личная свобода первыми попадают под удар военного
          времени, тогда как государственные структуры лишь разрастаются.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Войны часто начинаются с торговых ограничений. По меткому выражению
          Фредерика Бастиа, если товары не пересекают границы, то их пересекут
          армии. В условиях свободного рынка немыслимо, чтобы правительства
          развязывали войны: торгующие страны взаимно заинтересованы в
          сохранении открытых и дружественных отношений. Но стоит государству
          начать вести протекционистскую политику (направленную на уменьшение
          объемов торговли с помощью насилия), как оно наживает себе множество
          врагов, противостояние с которыми зачастую оборачивается военными
          конфликтами.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Войны могут быть выгодны только властвующей элите: непосредственному
          правительству и сросшейся с ним олигархии, которая возникает как раз
          из-за действий государства и наживается как на войне, так и на
          послевоенном восстановлении. Эти люди наживаются на чужом горе,
          они — главные бенефициары войн.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          «Свободный рынок способствует появлению монополий»
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          «…а большое и доброе государство — единственный способ решения этой
          проблемы». Утверждающие это люди вряд ли задумывались когда-нибудь
          о сущности государства. А ведь оно воплощает главную монополию, самую
          устойчивую из тех, которые только могут существовать — монополию на насилие.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Именно поэтому с помощью государства невозможно решить проблему
          образования монополий. Мало того, правительство, используя свои
          полномочия, регулярно наделяет привилегиями тех или иных
          производителей (хорошая почва для коррупции). Например, патент это
          государственная монополия на производство товаров определенного типа.
          Из-за этого справедливой конкуренции надолго приходит конец, а цены,
          соответственно, растут.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          На развитом свободном рынке возможно появление только временной
          монополии — и только в новообразовавшейся отрасли. Такая монополия
          обладает не менее шатким положением, чем любые другие игроки на
          рынке: стоит ей завысить цены, как появится огромное количество
          конкурентов. Некоторые монополии, впрочем, являются естественными:
          например, не везде можно проложить более одной дороги, и не всем
          хватит частот для радиовещания. Такие монополии будут существовать
          что на свободном, что на несвободном рынке.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          «Бедные беднеют, а&nbsp;богатые богатеют»
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Достаточно взглянуть на статистику (
          <Link
            href='https://ourworldindata.org/extreme-poverty'
            theme='sapphire'>
            Our world in data
          </Link>
          , на англ.яз.), чтобы всё понять:
        </Paragraph>
        <UList className={cnPage('List')}>
          <Paragraph size='m' theme='default'>
            В&nbsp;1981 году 44% людей по&nbsp;всему миру находились
            за&nbsp;гранью бедности. В&nbsp;2013&nbsp;— 10,7%.
          </Paragraph>
          <Paragraph size='m' theme='default'>
            В&nbsp;1990 году 2 миллиарда человек жили в&nbsp;абсолютной нищете.
            В&nbsp;2015&nbsp;— 705 миллионов. В&nbsp;среднем каждый день
            из&nbsp;нищеты выходили 137&nbsp;тысяч человек.
          </Paragraph>
          <Paragraph size='m' theme='default'>
            В&nbsp;1981 году только 9% населения бедных стран получало более
            $10 в&nbsp;день (по&nbsp;курсу 2011 года).
            В&nbsp;2013 году&nbsp;— 23%.
          </Paragraph>
        </UList>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          На свободном рынке богатеют абсолютно все, он выгоден не только для
          предпринимателей и богачей, но и для широких масс. Мы не считаем
          условия, в которых проходили эти изменения, «свободным рынком», но
          согласны, что он в целом куда свободнее, чем в прошлом. Смысл в том,
          что очень многие люди не в курсе, что бедность падает, считая при
          этом нынешний рынок «слишком свободным» и обвиняя его в росте
          бедности.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          «Свободный рынок способствует прямой диктатуре предпринимателей
          (угнетению или „эксплуатации“ наемных работников)»
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Доводы сторонников этого утверждения предполагают или доказывают,
          что работодатель находится априори в лучшем положении, чем наемный
          работник. Однако это подтверждается только человеческими словами,
          бытовым «общественным мнением», но не подтверждается человеческими
          действиями. Рабочие редко становятся работодателями, даже с поправкой
          на то, что «открыть бизнес дорого»: состоятельные наемные работники
          тоже становятся предпринимателями редко. Наконец, поставив себя на
          место предпринимателя, многие люди скорее всего согласятся, что быть
          предпринимателем нелегко. Предприниматель несет свои риски, в том
          числе такие, которые не существуют для наемного работника.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          «Свободный рынок способствует непрямой диктатуре предпринимателей
          (олигархии или коррупции)»
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Аргумент вида «у кого будут деньги, у того и будет власть». Следует
          отметить, что и олигархия, и коррупция — это уже реальность,
          независимо от либертарианства. При этом они присущи сильным
          государствам и страшны именно этим. Олигархия позволяет использовать
          нерыночные механизмы принуждения, которые существуют исключительно
          благодаря государству. Коррупция существует из-за того, что
          чиновник-взяткополучатель находится в привилегированном положении
          перед гражданином-взяткодателем и может диктовать ему условия, а не
          наоборот. И причины, и негативные последствия олигархии и
          коррупции — излишние полномочия государства и недостаточное разделение
          властей (излишняя централизация власти). Либертарианство противостоит
          обеим этим практикам и всегда находится на стороне жертвы против
          агрессора, независимо от того, сколько у агрессора денег и получил
          ли он их честно или нечестно.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          «Радикальные рыночные реформы приведут к&nbsp;тому, что у&nbsp;всех
          будут низкие зарплаты»
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Наемные работники могут торговаться (и торгуются) о зарплате сейчас.
          Нет никаких оснований полагать, что они перестанут торговаться о
          зарплате после рыночных (в том числе либертарианских) реформ.
          Наоборот, наращивание полномочий государства скорее поспособствует
          ограничению работника в возможности торговаться. Например, рабочие
          места, контролируемые государством, скорее будут оплачиваться менее
          гибко. Нет также никаких оснований полагать, что зафиксированная
          государством зарплата будет «высокой». Широкие государственные
          полномочия способствуют высокой эмиссии денег (как через создание
          наличных денег, так и через выдачу не обеспеченных ничем кредитов),
          что влечет снижение покупательной способности денег. Многие понимают
          это, даже не изучая экономическую теорию. Даже здравый смысл говорит:
          невозможно победить бедность назначением по стране заоблачной
          минимальной зарплаты. При этом тем же людям кажется: объяви
          минимальные зарплаты чуть более высокими, чем они есть сейчас, — и
          можно сделать людей чуточку богаче. Качественной разницы между двумя
          предложениями нет, есть только количественная. Первое сделает людей
          беднее мгновенно и явно, второе — медленно и незаметно. Не следует
          забывать, что либертарианцы выступают за ужесточенный контроль за
          государственными расходами и категорически против бэйлаутов, что
          повысит покупательскую способность и ценность всех «жёстких денег»,
          в том числе зарплат. Наконец, снижение налогового бремени тоже
          сделает всех богаче.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='religion'
          size='l'
          tag='h2'>
          Либертарианство и&nbsp;религия
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Мировые религии требуют от своих последователей не убивать и не
          воровать. Это написано в их священных текстах, и к этому священники
          призывают свою паству. Сказанного уже достаточно, чтобы
          либертарианство не было закрыто для религиозных людей. Принцип
          самопринадлежности означает, что никто не вправе запрещать другим
          людям ненасильственно практиковать религию, и уж подавно — запрещать
          им верить. В рамках контрактных юрисдикций могут формироваться
          либертарианские общества, где практикуются только отдельные религии.
          Поэтому у верующих людей есть немало оснований поддерживать
          либертарианскую платформу.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Есть люди, которые говорят о себе: я — либертарианец и при этом
          христианин / мусульманин / буддист. Есть общественные организации,
          которые можно описать как «либертарианские мусульманские» и
          «либертарианские христианские». Это не самое популярное направление
          либертарианской и окололибертарианской общественной деятельности,
          но тем не менее оно существует.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          История показывает, что конфликты между адептами разных религий
          (и&nbsp;особенно&nbsp;— религиозные войны) сходят на&nbsp;нет, как
          только становится популярной идея, что религия это личное дело
          граждан, а&nbsp;не&nbsp;часть сферы ответственности государства.
          Это пример того, как явно либертарианское решение показывает отличные
          результаты на&nbsp;практике.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Большинство либертарианцев, по всей видимости, атеисты или агностики,
          что вовсе не мешает им последовательно порицать насилие и сотрудничать
          с людьми иных взглядов для достижения общих политических целей,
          вытекающих из этого фундаментального принципа.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='ethics'
          size='l'
          tag='h2'>
          Этика и&nbsp;либертарианство
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В рамках этики люди пытаются найти ответ на вопрос, как следует
          поступать в различных ситуациях, как отделить хорошее от плохого.
          Можно сразу сказать, что либертарианство не стремится найти
          универсальный и всеобъемлющий ответ на этот вопрос. Либертарианская
          этика сводится к вопросу о том, когда оправдано применение силы.
          Найденный ответ можно коротко сформулировать так: «либертарианство
          всегда на стороне жертвы против агрессора».
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В либертарианстве есть два главных принципа: принцип
          самопринадлежности и принцип неагрессии. Любое действие оценивается
          на основе следования этим принципам. Если они соблюдаются — всё
          более-менее в порядке; если нет, то это плохо (аморально, неэтично
          и так далее). Важно, что действия оцениваются по соответствию
          определенным принципам, а не по тому, как мы воспринимаем их
          последствия. Хорошая цель не может оправдать плохие средства.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Возьмем крайний пример. Представим человека, которому нужно
          заработать на пропитание. Если его никуда не возьмут на работу, ему
          может грозить голодная смерть. Хорошо ли будет, если государство
          обяжет какого-нибудь работодателя устроить этого человека к себе на
          работу?
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Согласно либертарианской этике, такое трудоустройство — это безусловно
          плохое действие. Даже несмотря на то, что альтернатива грозит человеку
          голодной смертью.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Такая позиция может показаться ужасной, а либертарианцы —
          какими-нибудь кровожадными «социал-дарвинистами». Но представьте себя
          частным работодателем, которого обязали трудоустроить работника. Мало
          того, что «доброе дело» было совершено за чужой счет — государство
          решило за вас, кого вам следует нанимать на работу; теперь вы должны
          будете платить нежеланному работнику зарплату из своего бюджета, а
          лавры благодетеля скорее достанутся государству, чем человеку,
          которого пришлось заставлять делать доброе дело. Но, кроме того, это
          «доброе дело» было совершено насильно: вы никому не обязаны были
          предоставлять рабочие места, однако вашу свободу выбора в этом вопросе
          попросту отменили. Принудительное благодеяние нарушило свободу того,
          кого заставили это благодеяние оказать, — и поэтому оно расценивается
          в либертарианстве как плохой поступок.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Что же тогда остается делать безработному из нашего примера? Не
          следует делать вывод, что либертарианство одобряет смерть более
          слабых или отказ от помощи нуждающимся. Это не так. Либертарианство
          не запрещает помощь и тем более не поощряет какую-то особую форму
          эгоизма. Просто в рамках либертарианской этики оценка «хорошо» или
          «плохо» дается на основе соблюдения вышеуказанных принципов
          самопринадлежности и неагрессии — этим она и ограничивается.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Человеку можно помочь без принуждения. Другие люди вполне могут
          решить помочь нуждающемуся — либо куском хлеба, либо тем же
          трудоустройством. В свободном обществе куда больше, чем в несвободном,
          развита благотворительность — люди знают, что значит попасть в
          сложную ситуацию, и не ждут от государства помощи всем сирым и убогим,
          а берут дело в свои руки.
        </Paragraph>
        <iframe
          allow='autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
          className={cnPage('Video')}
          frameBorder='0'
          src='https://www.youtube.com/embed/TIrEbiUIVQQ' />
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Даже если окружающие решат иначе и откажутся помогать нуждающемуся —
          у них будет неотъемлемая свобода выбора принять то или иное решение.
          Будет ли такой отказ осуждаться либертарианским обществом? Вполне
          возможно, но этот вопрос уже выходит за рамки либертарианского учения.
          Мы утверждаем лишь, что добрые дела не делаются насильно, и что
          никакая благая цель не может оправдать агрессии, принуждения,
          покушения на чужую свободу и собственность. В отличие от других, мы
          делаем из этого однозначные, последовательные и предсказуемые
          политические выводы: что государство может делать, а что нет, и
          какие законы справедливы, а какие нет.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В конце концов, если окружающее сообщество человека решительно не
          устроит, он волен будет примкнуть к другому сообществу (или
          организовать свое) и жить по иным правилам. Либертарианство
          утверждает, что вы свободны добровольно объединяться со своими
          единомышленниками, строить такое общество, какое хотите, и
          договариваться о соблюдении тех этических норм, которые вам ближе.
          Либертарианцы против государственной дискриминации, но приветствуют
          частную.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='disputes'
          size='l'
          tag='h2'>
          Спорные вопросы в&nbsp;либертарианстве
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Большинство вопросов и&nbsp;проблем в&nbsp;либертарианстве можно
          рассмотреть и&nbsp;дать им однозначную оценку в&nbsp;рамках {}
          <Link href='#individual-sovereignty' theme='sapphire'>
            принципа самопринадлежности
          </Link>
          {} и&nbsp;{}
          <Link href='#nap' theme='sapphire'>
            принципа неагрессии
          </Link>.
          Тем не&nbsp;менее, в&nbsp;реальной жизни возникают ситуации,
          в&nbsp;которых сложно руководствоваться лишь ими. Рассмотрим лишь
          некоторые из&nbsp;них:
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          Спор об&nbsp;ограниченном государстве
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В&nbsp;основе этого спора лежит тезис, что в&nbsp;некоторых случаях
          государство может быть полезно, но&nbsp;должно существовать
          в&nbsp;ограниченных рамках только для поддержания порядка
          и&nbsp;защиты от&nbsp;внешней агрессии. {}
          <Link href='#minarchism-and-ancap' theme='sapphire'>
            Оппоненты
          </Link>
          {} считают, что такое государство всё равно будет существовать
          на&nbsp;принципах агрессивного насилия&nbsp;и принуждения
          и&nbsp;всегда будет стремиться расширить свои полномочия.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          Происхождение прав в&nbsp;теории права
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Точки зрения на&nbsp;происхождение прав можно разделить на&nbsp;две
          категории:
        </Paragraph>
        <OList className={cnPage('List')}>
          <Paragraph size='m' theme='default'>
            Права объективны, независимы от&nbsp;законов и&nbsp;человеческих
            договоренностей («естественное право»).
          </Paragraph>
          <Paragraph size='m' theme='default'>
            Все остальные точки зрения и&nbsp;подходы («контрактное право»,
            «законное право» или что-то еще).
          </Paragraph>
        </OList>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Среди либертарианцев встречаются как сторонники теории естественного
          неотчуждаемого права, так и сторонники других подходов.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          Субъектность ребенка
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианцы согласны с общепринятой точкой зрения о том, что человек
          не имет правовой субъектности с самого рождения. Но в то время как
          часть либертарианцев считает, что для обретения молодым человеком
          субъектности ему достаточно только заявить о ней, другая часть — что
          этому должно предшествовать нечто более существенное — например,
          обретение материальной независимости от родителей.
        </Paragraph>

        <Heading
          className={cnPage('SubSub')}
          size='m'
          tag='h3'>
          Допустимость либертарианской партийной деятельности
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Не все либертарианцы согласны, что либертарианские партии вообще
          должны существовать. Самый известный задокументированный {}
          <Link
            href='/posts/bef9f16d-7eca-4886-8e9a-c352c96a5949'
            theme='sapphire'>
            спор на эту тему
          </Link>
          {} состоялся между Мюрреем Ротбардом и Сэмюэлем Эдвардом Конкином
          Третьим. Либертарианцы-противники участия либертарианцев в
          обычной сегодняшней политической жизни не мешают
          либертарианцам-сторонникам такого участия. Одни вступают в
          либертарианские партии, другие — нет.
        </Paragraph>

        <Heading
          className={cnPage('Sub')}
          id='nolan-chart'
          size='l'
          tag='h2'>
          Положение либертарианцев на&nbsp;диаграмме Нолана
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Диаграмма Нолана&nbsp;— это популярная схема политического спектра,
          предложенная американским либертарианцем Дэвидом Ноланом в&nbsp;1969
          году. В&nbsp;стремлении избежать традиционного,
          но&nbsp;бесполезного {}
          <Link href='#left-and-right' theme='sapphire'>
            деления на «левых» и «правых»
          </Link>
          {} Нолан предложил классифицировать политические взгляды по&nbsp;двум
          основным критериям&nbsp;— по&nbsp;уровням личной и&nbsp;экономической
          свободы. В&nbsp;итоге возникает плоскость, где на&nbsp;одной оси
          отложено отношение человека к&nbsp;экономической свободе
          (от&nbsp;левых до&nbsp;правых, в&nbsp;сугубо экономическом смысле),
          а&nbsp;на другой&nbsp;— к&nbsp;личной свободе (от&nbsp;авторитаризма
          до&nbsp;либертарианства).
        </Paragraph>
        <img
          alt=''
          className={cnPage('Chart')}
          src={nolanChart}
          srcSet={`${nolanChartMobile} 550w, ${nolanChart} 700w`} />
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Получившуюся диаграмму можно разделить на&nbsp;сектора,
          соответствующие разным политическим философиям. Скажем, консерваторы
          чаще выступают за&nbsp;большую экономическую свободу, но&nbsp;также
          и&nbsp;за&nbsp;вмешательство государства в&nbsp;сферу личной свободы
          (например, наказание за&nbsp;употребление наркотиков). {}
          <Link href='#liberalism' theme='sapphire'>
            Левые либералы
          </Link>
          {} не&nbsp;согласны на&nbsp;такое вмешательство, но&nbsp;приветствуют
          государственный контроль в&nbsp;области экономики (например, МРОТ
          или государственную пенсионную систему).
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианцы выступают за&nbsp;максимальный уровень личной
          и&nbsp;экономической свободы, считая вредным и&nbsp;неправильным
          государственное вмешательство в&nbsp;деятельность людей. К&nbsp;этому
          сектору на&nbsp;диаграмме Нолана относится, в&nbsp;частности,
          и&nbsp;позиция Либертарианской партии России.
        </Paragraph>
      </PageText>
    </PageContent>
    <PageFooter />
  </Base>
)

export const PageNameLibertarianism = withBemMod(
  cnPage(),
  { name: 'libertarianism' },
  newBody
)
