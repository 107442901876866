import React from 'react'

import { cnLogo } from '../Logo'
import './Logo-Text.css'

export const LogoText = (props) => (
  <span className={cnLogo('Text')}>
    {props.children}
  </span>
)
