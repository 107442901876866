import React from 'react'
import { cn } from '@bem-react/classname'

import { FooterAside } from './-Aside/Footer-Aside'
import { FooterLayout } from './-Layout/Footer-Layout'
import { FooterMain } from './-Main/Footer-Main'
import './Footer.css'

export function cnFooter (...args) {
  return cn('Footer')(...args)
}

export const Footer = (props) => (
  <footer className={cnFooter(null, [props.className])}>
    <FooterLayout>
      <FooterMain />
      <FooterAside isPressButtonDisabled={props.isPressButtonDisabled} />
    </FooterLayout>
  </footer>
)
