import React from 'react'

import { cnContacts } from '../Contacts'
import './Contacts-Desc.css'

export const ContactsDesc = (props) => (
  <div className={cnContacts('Desc')}>
    {props.children}
  </div>
)
