import React from 'react'
import { withBemMod } from '@bem-react/core'

import Heading from '../../../Heading'
import Paragraph from '../../../Paragraph'
import { PageContent } from '../../-Content/Page-Content'
import { PageFooter } from '../../-Footer/Page-Footer'
import { PageHeader } from '../../-Header/Page-Header'
import { PageText } from '../../-Text/Page-Text'
import { cnPage } from '../../Page'
import './Page_name_platform.css'

const newBody = (Base, props) => (
  <Base {...props}>
    <PageHeader theme='default' activeLink='party' />
    <PageContent tag='main'>
      <PageText>
        <Heading size='l' tag='h1'>
          Платформа
        </Heading>
        <Heading className={cnPage('Sub')} size='m' tag='h2'>
          Наши принципы
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианство&nbsp;— политическая и&nbsp;правовая философия,
          согласно которой человек принадлежит только самому себе
          и&nbsp;свободен распоряжаться собой и&nbsp;своим имуществом любыми
          способами, не&nbsp;наносящими прямого ущерба другим людям и&nbsp;их
          имуществу. Ни&nbsp;другие индивиды, ни&nbsp;коллективы (включая
          государство) не&nbsp;вправе препятствовать человеку в&nbsp;его
          свободной ненасильственной деятельности. Отказаться
          от&nbsp;самопринадлежности невозможно.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианская партия России ставит своей целью проведение
          в&nbsp;России реформ, направленных на&nbsp;воплощение либертарианских
          принципов в&nbsp;общественной жизни.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Все политические партии в&nbsp;России в&nbsp;той или иной степени
          являются непоследовательными и&nbsp;популистскими. Наша партия бросает
          открытый вызов сложившейся политической системе. Мы не&nbsp;популисты,
          компилирующие свои программы по&nbsp;принципу политической
          злободневности. Мы&nbsp;— политическая сила, которая не&nbsp;боится
          говорить о&nbsp;своих идеях, даже если они идут вразрез
          с&nbsp;конъюнктурой. Мы не&nbsp;готовы поступаться своими принципами
          ради сиюминутной политической целесообразности и&nbsp;мы намерены
          неотступно реализовывать либертарианские реформы. Вместо популизма
          мы выбираем путь просвещения и&nbsp;честности.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Частная собственность
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Частная собственность является институтом, который позволяет людям
          взаимодействовать в&nbsp;мире ограниченных материальных ресурсов,
          не&nbsp;совершая насильственных действий. Человек вправе распоряжаться
          принадлежащим ему имуществом по&nbsp;своему усмотрению без
          ограничений, если такое использование не&nbsp;наносит прямого ущерба
          жизни, здоровью и&nbsp;собственности других людей. Никто не&nbsp;может
          быть лишён своего имущества, если оно приобретено правовым
          (ненасильственным) способом: куплено, получено в&nbsp;дар (включая
          наследство), в&nbsp;качестве компенсации за&nbsp;ущерб или приобретено
          по&nbsp;праву первого владельца.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          На&nbsp;своей территории (земельные участки, помещения, автомобили)
          собственник устанавливает правила поведения, при несоблюдении которых
          собственник вправе выдворить нарушителя. Собственник также вправе
          регламентировать порядок использования принадлежащего ему имущества
          другими людьми.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          «Интеллектуальная собственность»
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          «Интеллектуальная собственность»&nbsp;— общий термин для
          предоставляемых государством привилегий (называемых для их легитимации
          «правами») создателям различных интеллектуальных объектов. Основными
          элементами «интеллектуальной собственности» являются права авторов
          произведений (копирайт) и&nbsp;промышленная интеллектуальная
          собственность (изобретения, полезные модели, промышленные образцы,
          товарные знаки).
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Все объекты защиты со&nbsp;стороны законодательства
          об&nbsp;«интеллектуальной собственности» сходны в&nbsp;том, что они,
          хотя и&nbsp;могут иметь внешнее материальное воплощение, сами
          по&nbsp;себе существуют в&nbsp;форме идей в&nbsp;человеческом мозгу.
          Следовательно, с&nbsp;либертарианской точки зрения, в&nbsp;отношении
          этих объектов невозможно приобретение прав собственности, поскольку
          единственным возможным обоснованием прав с&nbsp;либертарианской точки
          зрения является защита от&nbsp;физических посягательств.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианцы не&nbsp;признают «прав интеллектуальной собственности»,
          так как они представляют собой ограничение на&nbsp;действия
          с&nbsp;материальными объектами, находящимися в&nbsp;чужой
          собственности, обеспечиваемое принуждением со&nbsp;стороны
          государства. У&nbsp;создателей интеллектуальных объектов существует
          немало возможностей рыночным путём получать выгоду от&nbsp;них.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Запрет агрессии (неспровоцированного насилия)
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Запрет агрессии (неспровоцированного насилия) является обратной
          стороной рассмотренных выше принципов самопринадлежности
          и&nbsp;неприкосновенности частной собственности. Неспровоцированное
          насилие&nbsp;— общий термин для обозначения действий, нарушающих эти
          принципы.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Насилие&nbsp;— осуществление физического воздействия на&nbsp;человека
          или на&nbsp;принадлежащее ему на&nbsp;праве собственности имущество
          без его на&nbsp;то&nbsp;согласия. В&nbsp;категорию насилия
          не&nbsp;попадают действия, хотя и&nbsp;сопряженные с&nbsp;физическим
          воздействием на&nbsp;других, но&nbsp;являющиеся базовыми условиями
          жизни человека (например, дыхание, разговор).
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Нарушение запрета неспровоцированного насилия влечёт за&nbsp;собой
          право индивида, чьи свобода или право собственности оказались
          нарушенными, на&nbsp;самозащиту, а&nbsp;также право других индивидов
          защищать его с&nbsp;его согласия.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Насилие с&nbsp;точки зрения либертарианства может быть только
          физическим воздействием или непосредственной физической его угрозой
          (покушение на&nbsp;убийство, физическая угроза оружием и&nbsp;проч.).
          К&nbsp;каким-либо речевым (разговорным, письменным) формам воздействия
          либертарианские правовые принципы неприменимы, агрессия через них
          осуществляться не&nbsp;может, и&nbsp;соответствующие действия
          не&nbsp;могут считаться их нарушением.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Прецедентное право
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Судебная система должна быть основана на&nbsp;принципе судебного
          прецедента. Необходимо по&nbsp;возможности избегать формулирования
          правовых норм в&nbsp;актах законодательства, поскольку, в&nbsp;отличие
          от&nbsp;судебного решения, которое может быть просто отвергнуто
          последующим судом как необоснованный прецедент, законодательный акт
          предполагается обязательным к&nbsp;исполнению судами вплоть
          до&nbsp;его отмены.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Отношение к государству. Минимальное государство (минархизм)
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Либертарианцы, как сторонники свободы, считают, что государства
          и&nbsp;типологически близкие к&nbsp;ним структуры, например,
          организованные преступные группы&nbsp;— суть корпорации, существующие
          за&nbsp;счёт физического насилия либо его угрозы. Современные
          государства являются наиболее ярко выраженной формой организованного
          насилия (агрессии), и&nbsp;их существование неотделимо
          от&nbsp;нарушения прав и&nbsp;свобод. Отличительной особенностью
          таких государств является монополия на&nbsp;применение агрессивного
          насилия на&nbsp;определённой территории и&nbsp;узаконивание этой
          монополии.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Тем не&nbsp;менее, либертарианцы считают допустимым существование
          территориального государства с&nbsp;минимальным набором полномочий
          («государство-ночной сторож»; другое название такого
          подхода&nbsp;— «минархизм»). Функции подобного государства ограничены
          узкими рамками&nbsp;— оборона территории, уголовное судопроизводство,
          дипломатия. При этом мы&nbsp;осознаём, что любое, даже самое
          ограниченное, государство стремится к&nbsp;расширению своих
          полномочий. Целям перехода к&nbsp;минимальному государству
          в&nbsp;России, на&nbsp;наш взгляд, в&nbsp;наибольшей степени будет
          соответствовать республиканская форма правления, при которой
          отсутствует институт президентства, минимальная исполнительная власть
          сосредоточена в&nbsp;руках назначаемого парламентом правительства,
          а&nbsp;определяющая роль отведена местному самоуправлению.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Положение в мире
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Многие общественные институты современного мира (пенсии, социальное
          обеспечение и&nbsp;т.&nbsp;п.) считаются незыблемыми и&nbsp;очень
          давними. Между тем, все они&nbsp;— феномен второй половины 20 века.
          На&nbsp;протяжении всего прошлого столетия мир через войны
          и&nbsp;кризисы последовательно отходил от&nbsp;принципов свободы
          в&nbsp;сторону большего государственного контроля. Девятнадцатый век
          не&nbsp;знал многих привычных для нас форм государственного
          принуждения; это был век стремительного экономического роста, когда
          и&nbsp;были заложены основы процветания, частично сохранившиеся
          до&nbsp;наших дней. Вся история 20&nbsp;века&nbsp;— это история
          разрастания большого государства, которое забирало всё больше
          и&nbsp;больше прав и&nbsp;свобод у&nbsp;граждан, зачастую прикрываясь
          демократическими процедурами и&nbsp;свободолюбивой риторикой. Культ
          большого государства нависает над миром.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          После крушения СССР многие провозглашали торжество либеральной
          демократии и&nbsp;«конец истории». Реальность&nbsp;же такова, что
          в&nbsp;развитых странах по-прежнему доминирует социал-демократическая
          идеология, в&nbsp;отстающих&nbsp;— ещё более враждебные ценностям
          свободы авторитарные доктрины, а&nbsp;в&nbsp;посткоммунистических
          влиятельны и&nbsp;те, и&nbsp;другие. Между тем, социал-демократические
          режимы сегодня переживают кризис, они погрязли в&nbsp;долгах,
          ослаблены, их способность влиять на&nbsp;развивающиеся страны, даже
          на&nbsp;своих союзников, падает. Существует серьёзная угроза новой
          глобальной волны авторитаризма, напоминающей пережитую миром
          в&nbsp;1920-х и&nbsp;1930-х. В&nbsp;этих условиях особую важность
          приобретает создание более последовательной и&nbsp;жизнеспособной
          модели свободного общества, чем социал-демократическая,
          и&nbsp;особенно актуальными становятся либертарианские принципы:
          именно они дают возможность покончить с&nbsp;кризисом социального
          государства и&nbsp;циклическими финансово-экономическими кризисами.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Кризис социального государства
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В&nbsp;рамках социал-демократии выросла обширная практика
          государственной опеки «от&nbsp;колыбели до&nbsp;могилы», начиная
          с&nbsp;обещаний обеспечить «бесплатные» образование и&nbsp;медицину,
          государственные пенсии и&nbsp;пособия и&nbsp;заканчивая попытками
          контролировать питание, курение, торговлю, рекламу, высказывания,
          прессу, образ мысли и&nbsp;т.&nbsp;п.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В&nbsp;современном мире наблюдается кризис социального государства,
          выражающийся во&nbsp;всё большей неспособности правительств выполнять
          взятые на&nbsp;себя обязательства. Госгарантии стимулируют людей
          меньше заботиться о&nbsp;будущем и&nbsp;больше потреблять
          в&nbsp;настоящем. Наиболее ярким свидетельством такого кризиса служит
          снижение рождаемости (обещания чиновников обеспечить всем
          и&nbsp;каждому «достойную старость» за&nbsp;счёт налогов, собираемых
          с&nbsp;молодого поколения, делают инвестиции в&nbsp;собственное
          потомство невыгодными). Вследствие этого пропорция между работающими
          и&nbsp;пенсионерами постоянно меняется в&nbsp;пользу последних, что
          приводит к&nbsp;всё возрастающей нагрузке на&nbsp;пенсионную систему,
          для поддержания которой требуется увеличение налогов и&nbsp;госдолга.
          Данная модель обречена на&nbsp;крах, и&nbsp;её существование было
          возможно только в&nbsp;странах, прошедших период свободного
          капитализма, в&nbsp;продолжение которого были накоплены богатства,
          нынче проедаемые социальным государством.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Прямым следствием концепции социального государства являются
          и&nbsp;миграционные проблемы, когда мигранты положительно
          дискриминируются, получая различного рода пособия и&nbsp;привилегии
          (в&nbsp;виде налоговых льгот). Спрос на&nbsp;мигрантов увеличивается
          также из-за того, что социальное государство высокими пособиями
          по&nbsp;безработице дестимулирует своих граждан к&nbsp;работе.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Экономический кризис
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Экономический кризис&nbsp;— системный сбой в&nbsp;рыночном процессе,
          вызывающий спад производства той или иной степени тяжести. Поскольку
          рыночный процесс является децентрализованным
          и&nbsp;самокорректирующимся, системные сбои в&nbsp;нем могут быть
          вызваны, главным образом, внешними по&nbsp;отношению к&nbsp;нему
          причинами. Ключевой такой причиной является государственное
          вмешательство.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Чаще всего первичной причиной экономического спада служит
          экспансионистская денежная политика эмитентов фиатных
          (не&nbsp;имеющих товарного обеспечения) денег. Сегодня такими
          эмитентами (в&nbsp;том числе и&nbsp;в&nbsp;России) являются
          центральные банки.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Центральные банки осуществляют эмиссию в&nbsp;основном
          не&nbsp;посредством прямого вливания денег в&nbsp;потребительский
          сектор экономики, а&nbsp;за&nbsp;счёт предоставления средств для
          последующего их кредитования полу-частным банкам (в&nbsp;значительной
          мере подконтрольным центральному банку). Последние за&nbsp;счёт этих
          средств и&nbsp;механизма банковского мультипликатора создают новые
          деньги свыше объёма средств, предоставляемых им вкладчиками. Иными
          словами, объём кредита в&nbsp;экономике превышает объём реальных
          сбережений. Это приводит к&nbsp;тому, что процентные ставки
          на&nbsp;рынке инвестиционного кредитования перестают отражать
          динамику межвременного предпочтения потребителей. Предприниматели
          инициируют инвестиционные проекты, для осуществления которых
          недостаточно ресурсов.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В&nbsp;конечном счёте, невозможность завершить ошибочно начатые
          из-за искажённых процентных ставок инвестиционные проекты обычно
          выражается в&nbsp;падении цен на&nbsp;акции на&nbsp;фондовых рынках,
          росте объемов невозврата по&nbsp;банковским кредитам
          и&nbsp;замораживании рынка межбанковского кредитования
          и&nbsp;аналогичных рынков. Негативные последствия искусственного бума
          часто усугубляются реакцией государства, основанной на&nbsp;идее,
          что кризис вызван эндогенными сбоями в&nbsp;рыночном процессе.
          Антикризисное вмешательство осуществляется в&nbsp;различных формах
          (попытки контролировать цены и&nbsp;ставки заработной платы,
          наращивание государственных расходов, национализация предприятий,
          спасение несостоятельных фирм за&nbsp;счёт средств
          налогоплательщиков), но&nbsp;всегда выливается в&nbsp;затяжной кризис,
          а&nbsp;иногда и&nbsp;в&nbsp;его значительное усугубление вплоть
          до&nbsp;тяжелейшей экономической депрессии (например, Великая
          депрессия в&nbsp;США или мировой финансовый кризис 2008&nbsp;года).
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Поскольку экономические кризисы вызываются в&nbsp;основном
          государственным вмешательством в&nbsp;экономику, единственным способом
          их предотвращения является прекращение такого вмешательства.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Политкорректность, ксенофобия и&nbsp;личные права
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Социал-демократические режимы широко используют различные формы
          ограничений свободы слова, прикрываясь защитой прав «меньшинств»
          и&nbsp;борьбой с&nbsp;ксенофобией. Тем самым они подменяют
          представление об&nbsp;индивиде как единственном меньшинстве, чьи
          права и&nbsp;свободы требуют защиты, идеей «групповых прав», стоящих
          выше индивидуальных. Подобные практики заимствуются развивающимися
          и&nbsp;посткоммунистическими странами, где превращаются
          в&nbsp;опаснейшее орудие полицейского произвола, как, например,
          ст.&nbsp;282 УК РФ.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Государство неспособно решить проблему ксенофобии. Силовыми методами
          оно навязывает толерантность и&nbsp;само становится главным ксенофобом
          по&nbsp;отношению к&nbsp;тем, кто отказывается следовать мейнстримным
          установкам политкорректности. Единственная функция
          государства&nbsp;— пресекать посягательства на&nbsp;жизнь
          и&nbsp;свободу, а&nbsp;не&nbsp;указывать гражданам, что им думать
          и&nbsp;как выражать свои мысли.
        </Paragraph>
        <Heading className={cnPage('Sub')} size='m' tag='h2'>
          Эта страна
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          В&nbsp;истории нашей страны всегда хватало тирании и&nbsp;жестокости.
          Создавая мнимый образ внешнего или внутреннего врага, властители
          отбирали у&nbsp;нас права и&nbsp;свободы. И&nbsp;сегодня машина
          государственной пропаганды работает на&nbsp;полную мощность, пытаясь
          отвлечь нас от&nbsp;действительных причин неурядиц в&nbsp;стране.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Власть в&nbsp;современной России (узурпация, авторитаризм, популизм)
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Российская политическая система сохранила многие качества советской
          системы эпохи застоя и&nbsp;по-прежнему стремится регламентировать
          все отрасли человеческой деятельности. Помимо общих проблем,
          свойственных большому государству, российская власть имеет
          и&nbsp;свои особенности, связанные с&nbsp;узурпацией власти Владимиром
          Путиным, установившим в&nbsp;стране режим личной диктатуры.
          До&nbsp;последнего времени можно было говорить об&nbsp;электоральной
          диктатуре, однако после массовых фальсификаций на&nbsp;выборах
          в&nbsp;декабре 2011 и&nbsp;марте 2012 основой режима стал аппарат
          государственного насилия.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Наши цели идут дальше замены одной персоналии на&nbsp;другую
          и&nbsp;справедливого суда над представителями преступного режима.
          Намного важнее&nbsp;— реализация тех принципов свободы
          и&nbsp;справедливости, из&nbsp;которых вытекает данная программа.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Преступления прошлого. Люстрации
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Одна из&nbsp;главных ошибок переходного периода 1990-х в&nbsp;России
          связана с&nbsp;тем, что так и&nbsp;не&nbsp;была дана оценка
          совершённым СССР преступлениям, за&nbsp;которые несут ответственность
          КПСС и&nbsp;лично руководители партийных и&nbsp;силовых органов, равно
          как и&nbsp;конкретные исполнители их распоряжений и&nbsp;приказов.
          Мы&nbsp;считаем, что дать оценку таким событиям никогда
          не&nbsp;поздно. Для этого в&nbsp;первую очередь необходимо раскрыть
          все засекреченные документы из&nbsp;советских и&nbsp;российских
          архивов. Любой, кто считает себя или своих близких жертвами
          преступлений режима, сможет на&nbsp;основании вновь открытых
          документов подать в&nbsp;суд на&nbsp;людей, совершивших преступления
          и&nbsp;не&nbsp;понесших наказания. Следует особо подчеркнуть, что
          противоправные деяния, совершённые в&nbsp;соответствии
          с&nbsp;законодательством СССР, остаются противоправными деяниями
          и&nbsp;подлежат суду по&nbsp;аналогии с&nbsp;нюрнбергским прецедентом.
          На&nbsp;основании судебных решений будут проводиться люстрации
          с&nbsp;пожизненным запретом виновным занимать государственные
          должности.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Распад страны
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Территориальная целостность государства долго выдавалась идеологами
          разных эпох за&nbsp;высшую цель, за&nbsp;которую стоит платить любой
          ценой, в&nbsp;том числе и&nbsp;жизнями граждан. Распад страны
          практически неизбежен в&nbsp;случае централизации власти
          и&nbsp;интервенционистской политики, когда центр отбирает
          у&nbsp;граждан полномочие за&nbsp;полномочием. Имперская политика,
          направленная на&nbsp;завоевание новых территорий и&nbsp;укрепление
          «целостности страны», приводила к&nbsp;ещё большему закрепощению
          и&nbsp;угнетению народа.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Мы&nbsp;выступаем за&nbsp;широкую децентрализацию власти,
          за&nbsp;укрепление региональной и&nbsp;муниципальной автономии.
          Мы&nbsp;считаем, что чем больше российские власти будут пренебрегать
          этими принципами, тем сильнее в&nbsp;итоге сожмутся границы страны
          и&nbsp;тяжелее будут сопутствующие потрясения.
        </Paragraph>
        <Heading className={cnPage('SubSub')} size='s' tag='h3'>
          Государственный «капитализм»
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Реформы 1990-х, ошибочно воспринимаемые как рыночные
          и&nbsp;направленные на&nbsp;воплощение принципов свободы и&nbsp;права,
          на&nbsp;практике привели к&nbsp;созданию системы «государственного
          капитализма», так как были не&nbsp;просто половинчаты, но&nbsp;носили
          отчётливо (и&nbsp;зачастую намеренно) нерыночный характер. Главная их
          ошибка состояла в&nbsp;том, что, допустив неоправданные компромиссы
          с&nbsp;деятелями старой номенклатуры, реформаторы 90-х так
          и&nbsp;не&nbsp;смогли поверить в&nbsp;рынок, и&nbsp;контроль над
          экономической ситуацией в&nbsp;стране был делегирован государственным
          чиновникам и&nbsp;силовикам. В&nbsp;2000-е годы это неизбежно привело
          к&nbsp;фактическому срастанию государства с&nbsp;формально частным
          капиталом, к&nbsp;расширению административно-командных отношений
          в&nbsp;экономике, к&nbsp;подавлению малого и&nbsp;среднего бизнеса,
          к&nbsp;чудовищной коррупции. Таким образом, вместо развития свободной
          торговли и&nbsp;предпринимательства российская экономика
          характеризуется бездумно растранжиренными бюджетными вливаниями,
          подавлением частной инициативы и&nbsp;бессовестным лоббизмом.
        </Paragraph>
        <Heading className={cnPage('Sub')} size='m' tag='h2'>
          Наша страна
        </Heading>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Мы&nbsp;установим в&nbsp;стране правопорядок, предоставим гарантии
          свободы и&nbsp;безопасности каждому жителю России. Дадим возможность
          для комфортной и&nbsp;счастливой жизни. Создадим в&nbsp;нашей стране
          условия, когда граждане ограничивают государство, а&nbsp;не&nbsp;оно
          их. Государство будет общаться с&nbsp;гражданами как
          с&nbsp;неприкосновенными индивидами, которых невозможно использовать
          как средство или ресурс, общаться с&nbsp;почтением, которого
          мы&nbsp;заслуживаем как обладатели индивидуальных прав.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Уважая наши права и&nbsp;тем самым уважая нас, государство займёт
          положенное ему место охранителя нашей безопасности, позволит нам
          самим решать и&nbsp;самостоятельно отвечать за&nbsp;свою жизнь
          и&nbsp;реализовывать свои цели и&nbsp;мечты, опираясь
          на&nbsp;добровольное сотрудничество с&nbsp;другими людьми.
        </Paragraph>
        <Paragraph className={cnPage('Par')} size='m' theme='default'>
          Конкретные направления и&nbsp;стратегии реформирования, вытекающие
          из&nbsp;нашей Платформы, представлены в&nbsp;Программе Либертарианской
          партии России.
        </Paragraph>
      </PageText>
    </PageContent>
    <PageFooter />
  </Base>
)

export const PageNamePlatform = withBemMod(
  cnPage(),
  { name: 'platform' },
  newBody
)
