import React from 'react'

import { HomeAllContacts } from '../-AllContacts/Home-AllContacts'
import {
  HomeArbitrationCommittee
} from '../-ArbitrationCommittee/Home-ArbitrationCommittee'
import {
  HomeControlCommission
} from '../-ControlCommission/Home-ControlCommission'
import {
  HomeFederalCommittee
} from '../-FederalCommittee/Home-FederalCommittee'
import { HomePanel } from '../-Panel/Home-Panel'
import { HomeTab } from '../-Tab/Home-Tab'
import { HomeTabs } from '../-Tabs/Home-Tabs'
import { cnHome } from '../Home'
import './Home-Contacts.css'

export class HomeContacts extends React.Component {
  constructor (props) {
    super(props)

    this.handleAllContactsClick =
      this.handleAllContactsClick.bind(this)
    this.handleArbitrationCommitteeClick =
      this.handleArbitrationCommitteeClick.bind(this)
    this.handleControlCommissionClick =
      this.handleControlCommissionClick.bind(this)
    this.handleFederalCommitteeClick =
      this.handleFederalCommitteeClick.bind(this)

    this.state = { activeTab: 'all' }
  }

  handleAllContactsClick (e) {
    e.preventDefault()
    this.setState({ activeTab: 'all' })
  }

  handleArbitrationCommitteeClick (e) {
    e.preventDefault()
    this.setState({ activeTab: 'ac' })
  }

  handleControlCommissionClick (e) {
    e.preventDefault()
    this.setState({ activeTab: 'cc' })
  }

  handleFederalCommitteeClick (e) {
    e.preventDefault()
    this.setState({ activeTab: 'fc' })
  }

  render () {
    return (
      <div className={cnHome('Contacts')}>
        <HomeTabs mobile>
          <HomeTab
            active={this.state.activeTab === 'all'}
            onClick={this.handleAllContactsClick}>
            Все
          </HomeTab>
          <HomeTab
            active={this.state.activeTab === 'fc'}
            onClick={this.handleFederalCommitteeClick}>
            ФК
          </HomeTab>
          <HomeTab
            active={this.state.activeTab === 'cc'}
            onClick={this.handleControlCommissionClick}>
            ЦКРК
          </HomeTab>
          <HomeTab
            active={this.state.activeTab === 'ac'}
            onClick={this.handleArbitrationCommitteeClick}>
            ЭК
          </HomeTab>
        </HomeTabs>
        <HomeTabs desktop>
          <HomeTab
            active={this.state.activeTab === 'all'}
            onClick={this.handleAllContactsClick}>
            Все
          </HomeTab>
          <HomeTab
            active={this.state.activeTab === 'fc'}
            onClick={this.handleFederalCommitteeClick}>
            Федеральный комитет
          </HomeTab>
          <HomeTab
            active={this.state.activeTab === 'cc'}
            onClick={this.handleControlCommissionClick}>
            Центральная контрольно-ревизионная комиссия
          </HomeTab>
          <HomeTab
            active={this.state.activeTab === 'ac'}
            onClick={this.handleArbitrationCommitteeClick}>
            Этический комитет
          </HomeTab>
        </HomeTabs>
        <HomePanel hidden={!(this.state.activeTab === 'all')}>
          <HomeAllContacts />
        </HomePanel>
        <HomePanel hidden={!(this.state.activeTab === 'fc')}>
          <HomeFederalCommittee />
        </HomePanel>
        <HomePanel hidden={!(this.state.activeTab === 'cc')}>
          <HomeControlCommission />
        </HomePanel>
        <HomePanel hidden={!(this.state.activeTab === 'ac')}>
          <HomeArbitrationCommittee />
        </HomePanel>
      </div>
    )
  }
}
