import React from 'react'
import { withBemMod } from '@bem-react/core'

import Home from '../../../Home'
import { PageContent } from '../../-Content/Page-Content'
import { PageFooter } from '../../-Footer/Page-Footer'
import { PageHeader } from '../../-Header/Page-Header'
import { cnPage } from '../../Page'
import './Page_name_party.css'

const newBody = (Base, props) => (
  <Base {...props}>
    <PageHeader activeLink='party' theme='default' />
    <PageContent tag='main'>
      <Home />
    </PageContent>
    <PageFooter />
  </Base>
)

export const PageNameParty = withBemMod(
  cnPage(),
  { name: 'party' },
  newBody
)
