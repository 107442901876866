import { compose } from '@bem-react/core'

import { Menu as BaseMenu } from './Menu'
import { MenuClosed } from './_closed/Menu_closed'

const Menu = compose(
  MenuClosed
)(BaseMenu)

export default Menu
