import React from 'react'
import { classnames } from '@bem-react/classnames'
import { cn } from '@bem-react/classname'

import Button from '../Button'
import Heading from '../Heading'
import Link from '../Link'
import Paragraph from '../Paragraph'

import { HomeContacts } from './-Contacts/Home-Contacts'
import './Home.css'

export function cnHome (...args) {
  return cn('Home')(...args)
}

export const Home = (props) => (
  <div className={cnHome(null, [props.className])}>
    <div className={cnHome('Jumbo')}>
      <Heading className={cnHome('Head')} size='m' tag='h1'>
        Либертарианская партия
      </Heading>
      <div className={cnHome('Triad')}>
        <section className={cnHome('Section')}>
          <Heading className={cnHome('Sub')} size='s' tag='h2'>
            <Link
              className={cnHome('Link')}
              href='/docs/Программа.pdf'
              theme='baikal'>
              Программа
            </Link>
          </Heading>
          <Paragraph className={cnHome('Par')} size='s' theme='lighter'>
            В&nbsp;основе нашей программы принцип самопринадлежности
            и&nbsp;запрет на&nbsp;агрессивное насилие. Программа минархическая,
            подразумевает существование минимального государства.
          </Paragraph>
        </section>
        <section className={cnHome('Section')}>
          <Heading className={cnHome('Sub')} size='s' tag='h2'>
            <Link className={cnHome('Link')} href='/platform' theme='baikal'>
              Платформа
            </Link>
          </Heading>
          <Paragraph className={cnHome('Par')} size='s' theme='lighter'>
            Если программа это план действий, то&nbsp;платформа&nbsp;— наш
            взгляд на&nbsp;современные проблемы в&nbsp;более свободной форме.
          </Paragraph>
        </section>
        <section className={cnHome('Section')}>
          <Heading className={cnHome('Sub')} size='s' tag='h2'>
            История
          </Heading>
          <Paragraph className={cnHome('Par')} size='s' theme='lighter'>
            Партия начала формироваться в&nbsp;Санкт-Петербурге в&nbsp;конце
            2007 года. В&nbsp;марте 2008 года в&nbsp;Москве состоялась
            учредительная конференция, на&nbsp;которой был принят устав. Датой
            основания партии считается 15 апреля 2008 года, когда
            в&nbsp;Министерство юстиции было подано уведомление
            о&nbsp;регистрации первого оргкомитета.
          </Paragraph>
        </section>
      </div>
    </div>

    <Heading
      className={classnames(cnHome('Sub'), cnHome('DesktopCentered'))}
      size='s'
      tag='h2'>
      Лица партии
    </Heading>
    <HomeContacts />

    <div className={cnHome('Triad')}>
      <section className={cnHome('Section')}>
        <Heading className={cnHome('Sub')} size='s' tag='h2'>
          Членство в&nbsp;партии
        </Heading>
        <Paragraph className={cnHome('Par')} size='s' theme='lighter'>
          Партия знакомит с&nbsp;людьми схожих взглядов. Членство позволяет
          влиять на&nbsp;партию изнутри, участвовать в&nbsp;мероприятиях,
          организовывать их, совместно реализовывать программу партии
          и&nbsp;получать поддержку.
        </Paragraph>
        <Button
          className={cnHome('Button')}
          theme='primary'
          type='link'
          uri='/join'>
          Вступить в&nbsp;партию
        </Button>
      </section>
      <section className={cnHome('Section')}>
        <Heading className={cnHome('Sub')} size='s' tag='h2'>
          Правозащита
        </Heading>
        <Paragraph className={cnHome('Par')} size='s' theme='lighter'>
          В&nbsp;случае проблем с&nbsp;правоохранительными органами членам
          и&nbsp;сторонникам партии помогает Правозащитная группа&nbsp;ЛПР.
          По&nbsp;всем вопросам пишите: {}
          <Link
            href='mailto:pz@lp-russia.org'
            theme='default'>
            pz@lp-russia.org
          </Link>
        </Paragraph>
      </section>
      <section className={cnHome('Section')}>
        <Heading className={cnHome('Sub')} size='s' tag='h2'>
          <Link
            className={cnHome('Link')}
            href='/docs/Устав.pdf'
            theme='baikal'>
            Устав
          </Link>
        </Heading>
        <ul className={cnHome('List')}>
          <li className={cnHome('ListItem')}>
            <Link href='/docs/Декларация_о_принципах.pdf' theme='default'>
              Декларация о&nbsp;принципах
            </Link>
          </li>
          <li className={cnHome('ListItem')}>
            <Link href='/docs/Положение_о_членстве.pdf' theme='default'>
              Положение о&nbsp;членстве
            </Link>
          </li>
          <li className={cnHome('ListItem')}>
            <Link href='/docs/Положение_о_сторонниках.pdf' theme='default'>
              Положение о&nbsp;сторонниках
            </Link>
          </li>
        </ul>
      </section>
    </div>
  </div>
)
