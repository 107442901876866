import { compose } from '@bem-react/core'

import { Paragraph as BaseParagraph } from './Paragraph'
import { ParagraphSizeM } from './_size/Paragraph_size_m'
import { ParagraphSizeS } from './_size/Paragraph_size_s'
import { ParagraphThemeDefault } from './_theme/Paragraph_theme_default'
import { ParagraphThemeLighter } from './_theme/Paragraph_theme_lighter'

const Paragraph = compose(
  ParagraphSizeM,
  ParagraphSizeS,
  ParagraphThemeDefault,
  ParagraphThemeLighter
)(BaseParagraph)

export default Paragraph
