import React from 'react'
import { cn } from '@bem-react/classname'

import { MenuHeader } from './-Header/Menu-Header'
import { MenuNav } from './-Nav/Menu-Nav'
import './Menu.css'

export function cnMenu (...args) {
  return cn('Menu')(...args)
}

export const Menu = (props) => (
  <div className={cnMenu(null, [props.className])}>
    <MenuHeader onCloseClick={props.onClose} />
    <MenuNav activeItem={props.activeItem} />
  </div>
)
