import React from 'react'

import { ContactLink } from '../-Link/Contact-Link'
import { cnContact } from '../Contact'

export const ContactTelegram = (props) => (
  <span className={cnContact('Telegram')}>
    телеграм:&nbsp;
    <ContactLink href={`tg://resolve?domain=${props.children}`}>
      {`@${props.children}`}
    </ContactLink>
  </span>
)
