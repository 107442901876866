import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnHeader } from '../Header'
import './Header_theme_negative.css'

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.burgerTheme = 'negative'
  newProps.logoTheme = 'negative'

  return <Base {...newProps} />
}

export const HeaderThemeNegative = withBemMod(
  cnHeader(),
  { theme: 'negative' },
  newBody
)
