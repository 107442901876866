import React from 'react'

import Link from '../../Link'
import { cnHome } from '../Home'
import { HomeTabActive } from './_active/Home-Tab_active'
import './Home-Tab.css'

const BaseHomeTab = (props) => (
  <Link
    className={cnHome('Tab', [props.className])}
    href='#'
    theme='default'
    onClick={props.onClick}>
    {props.children}
  </Link>
)

export const HomeTab = HomeTabActive(BaseHomeTab)
