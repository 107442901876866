import React from 'react'

import Contact from '../../Contact'
import Contacts from '../../Contacts'
import Link from '../../Link'

import luzin from '../images/luzin.jpg'
import luzin2x from '../images/luzin@2x.jpg'
import naumov from '../images/naumov.jpg'
import naumov2x from '../images/naumov@2x.jpg'
import svetov from '../images/svetov.jpg'
import svetov2x from '../images/svetov@2x.jpg'
import toporov from '../images/toporov.jpg'
import toporov2x from '../images/toporov@2x.jpg'

export const HomeArbitrationCommittee = () => (
  <Contacts
    desc={
      <React.Fragment>
        орган для апелляции при исключении из&nbsp;партии, отказе
        во&nbsp;вступлении, а&nbsp;также необоснованных решений Федерального
        комитета партии, включая председателя. {}
        <Link href='mailto:etcom@lp-russia.org' theme='default'>
          etcom@lp-russia.org
        </Link>
      </React.Fragment>
    }
    title='Этический комитет'
    withLabel>
    <Contact
      name='Иван Лузин'
      photo={luzin}
      photo2x={luzin2x}
      position='Этический комитет'
      telegram='RiardenSteel' />
    <Contact
      name='Тимур Наумов'
      photo={naumov}
      photo2x={naumov2x}
      position='Этический комитет' />
    <Contact
      email='svetov@lp-russia.org'
      name='Михаил Светов'
      photo={svetov}
      photo2x={svetov2x}
      position='Этический комитет'
      telegram='mr_libertarian' />
    <Contact
      name='Евгений Топоров'
      photo={toporov}
      photo2x={toporov2x}
      position='Этический комитет'
      telegram='evgenitop' />
  </Contacts>
)
