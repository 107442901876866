/* global FormData, fetch */
import React from 'react'
import { addNewBlock } from 'medium-draft'

import { cnEditor } from '../Editor'

import './Editor-PictureUpload.css'

export class EditorPictureUpload extends React.Component {
  constructor (props) {
    super(props)

    this.onChange = this.onChange.bind(this)
  }

  onChange (e) {
    const file = e.target.files[0]

    if (file.type.indexOf('image/') !== 0) {
      return
    }

    const formData = new FormData()
    formData.append('illustration[post_id]', document.location.pathname.match(/posts\/([^/]*)/)[1])
    formData.append('illustration[picture_attributes][file]', file)
    fetch('/admin/illustrations', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': decodeURIComponent(
          document.cookie.match(/CSRF-TOKEN=([^;]*)/)[1]
        )
      },
      body: formData
    }).then((response) => {
      if (response.status === 200) {
        return response.json().then((data) => {
          if (data.uri) {
            this.props.setEditorState(
              addNewBlock(
                this.props.getEditorState(),
                'atomic:image',
                { src: data.uri }
              )
            )
          }
        })
      }
    })
    this.props.close()
  }

  render () {
    return (
      <input
        accept='image/*'
        className={cnEditor('PictureUpload')}
        onChange={this.onChange}
        type='file' />
    )
  }
}
