import React from 'react'

export const TelegramIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg'
    height='14'
    width='17'
    viewBox='0 0 17 14'>
    <g fill='none' fillRule='nonzero'>
      <path fill='#d8e4ee'
        d='M 6.236,12.982
           c -0.494,0 -0.41,-0.187 -0.581,-0.658
           L 4.2,7.535
             15.4,0.891' />
      <path fill='#c1d7e5'
        d='M 6.236,13.057
           c 0.382,0 0.55,-0.174 0.764,-0.382
           l 2.036,-1.98
             -2.54,-1.531' />
      <path fill='#fff'
        d='M 6.506,9.193
           l 6.155,4.547
           c 0.703,0.388 1.21,0.187 1.384,-0.652
           l 2.506,-11.806
           c 0.256,-1.028 -0.392,-1.495 -1.064,-1.19
           l -14.712,5.673
           c -1.004,0.403 -0.998,0.963 -0.183,1.212
           l 3.776,1.179
             8.74,-5.514
           c 0.413,-0.25 0.791,-0.116 0.48,0.16' />
    </g>
  </svg>
)
