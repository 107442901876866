import React from 'react'
import { cn } from '@bem-react/classname'

import './Social.css'

export const cnSocial = cn('Social')

export const Social = (props) => (
  <a className={cnSocial(null, [props.className])} href={props.uri}>
    {props.icon}
  </a>
)
