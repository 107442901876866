import React from 'react'
import { cn } from '@bem-react/classname'

import Heading from '../Heading'
import { ContactsDesc } from './-Desc/Contacts-Desc'
import { ContactsItem } from './-Item/Contacts-Item'
import { ContactsLabel } from './-Label/Contacts-Label'
import './Contacts.css'

export const cnContacts = cn('Contacts')

export const Contacts = (props) => (
  <div className={cnContacts(null, [props.className])}>
    {props.withLabel &&
      <ContactsItem>
        <ContactsLabel>
          <Heading size='s'>{props.title}</Heading>
          {props.desc && <ContactsDesc>{props.desc}</ContactsDesc>}
        </ContactsLabel>
      </ContactsItem>
    }

    {React.Children.map(props.children, (child) => (
      <ContactsItem>{child}</ContactsItem>
    ))}
  </div>
)
