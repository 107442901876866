import React from 'react'

import { cnContact } from '../Contact'
import './Contact-Name.css'

export const ContactName = (props) => (
  <div className={cnContact('Name')}>
    {props.children}
  </div>
)
