import React from 'react'
import { cn } from '@bem-react/classname'

export const cnPage = cn('Page')

export const Page = (props) => (
  <div className={cnPage(null, [props.className])}>
    {props.children}
  </div>
)
