import React from 'react'

import Link from '../../Link'
import { MenuItemActive } from './_active/Menu-Item_active'
import { cnMenu } from '../Menu'
import './Menu-Item.css'

export const BaseMenuItem = (props) => (
  <li className={cnMenu('Item', [props.className])}>
    <Link href={props.uri}>
      {props.children}
    </Link>
  </li>
)

export const MenuItem = MenuItemActive(BaseMenuItem)
