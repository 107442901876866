import React from 'react'
import { cn } from '@bem-react/classname'

import { UListItem } from './-Item/UList-Item'
import './UList.css'

export const cnUList = cn('UList')

export const UList = (props) => (
  <ul className={cnUList(null, [props.className])}>
    {React.Children.toArray(props.children).map((child, i) => (
      <UListItem key={i}>
        {child}
      </UListItem>
    ))}
  </ul>
)
