import React from 'react'
import { cn } from '@bem-react/classname'

import './Italic.css'

export const cnItalic = cn('Italic')

export const Italic = (props) => (
  <i className={cnItalic(null, [props.className])}>
    {props.children}
  </i>
)
