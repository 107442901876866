import React from 'react'
import { compose } from '@bem-react/core'

import Button from '../../Button'
import { cnFooter } from '../Footer'
import { FooterChatLinkDesktop } from './_desktop/Footer-ChatLink_desktop'
import { FooterChatLinkMobile } from './_mobile/Footer-ChatLink_mobile'

export const BaseFooterChatLink = (props) => (
  <Button
    className={cnFooter('ChatLink', [props.className])}
    theme='telegram'
    type='link'
    uri='https://t.me/lpch_tg'>
    {props.children}
  </Button>
)

export const FooterChatLink = compose(
  FooterChatLinkDesktop,
  FooterChatLinkMobile
)(BaseFooterChatLink)
