import React from 'react'
import { cn } from '@bem-react/classname'

import { LogoIcon } from './-Icon/Logo-Icon'
import { LogoText } from './-Text/Logo-Text'
import './Logo.css'

export function cnLogo (...args) {
  return cn('Logo')(...args)
}

export const Logo = (props) => (
  <div className={cnLogo(null, [props.className])}>
    <LogoIcon theme={props.iconTheme} />
    {props.children && <LogoText>{props.children}</LogoText>}
  </div>
)
