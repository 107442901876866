import React from 'react'

import Link from '../../Link/index.js'
import { cnFileDL } from '../FileDL.js'
import './FileDL-Copyright.css'

export const FileDLCopyright = ({ license }) => {
  let link

  switch (license) {
    case 'CC BY 4.0':
      link = 'https://creativecommons.org/licenses/by/4.0/'
      break
    case 'CC0 1.0':
      link = 'https://creativecommons.org/publicdomain/zero/1.0/'
      break
    case undefined:
      return null
    default:
      throw new Error(`Unknown license: ${license}`)
  }

  return (
    <p className={cnFileDL('Copyright')}>
      Доступно на условиях <Link theme='default' href={link}>{license}</Link>.
    </p>
  )
}
