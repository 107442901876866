import React from 'react'

import { ContactLink } from '../-Link/Contact-Link'
import { cnContact } from '../Contact'

export const ContactPhone = (props) => (
  <ContactLink
    className={cnContact('Phone')}
    href={`tel:${props.children}`}>
    {props.children}
  </ContactLink>
)
