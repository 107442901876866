import React from 'react'

import { cnHeader } from '../Header'
import { HeaderLink } from '../-Link/Header-Link'
import './Header-Sections.css'

export const HeaderSections = (props) => (
  <div className={cnHeader('Sections')}>
    <HeaderLink
      active={props.activeLink === 'posts'}
      uri='/posts'>
      Публикации
    </HeaderLink>
    <HeaderLink
      active={props.activeLink === 'party'}
      uri='/'>
      Партия
    </HeaderLink>
    <HeaderLink
      active={props.activeLink === 'libertarianism'}
      uri='/libertarianism'>
      Либертарианство
    </HeaderLink>
  </div>
)
