import React from 'react'

export const BurgerIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg'
    height='11'
    width='16'
    viewBox='0 0 16 11'>
    <g fill={props.color} fillRule='nonzero' opacity={props.opacity}>
      <rect x='0' y='0' width='16' height='1' />
      <rect x='3' y='5' width='13' height='1' />
      <rect x='1' y='10' width='15' height='1' />
    </g>
  </svg>
)
