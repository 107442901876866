import React from 'react'
import { compose } from '@bem-react/core'

import { cnLogo } from '../Logo'
import { LogoIconThemeDefault } from './_theme/Logo-Icon_theme_default'
import { LogoIconThemeNegative } from './_theme/Logo-Icon_theme_negative'

const SVG = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg'
    height='22'
    width='23'
    viewBox='0 0 23 22'>
    <g fill='none' fillRule='evenodd'>
      <path fill={props.topArc}
        opacity={props.opacity}
        d='M 3.286,6.778
           a 9.668,9.668 0,0,1 7.893,-4.098
           c 1.374,0 2.737,0.298 3.984,0.866
           A 9.402,9.402 0,0,0 9.5,1.656
           a 9.5,9.5 0,0,0 -7.756,4.027
           A 9.493,9.493 0,0,0 0.14,12.794
           a 9.468,9.468 0,0,0 4.065,6.3
             9.702,9.702 0,0,1 -0.918,-12.316' />
      <path fill={props.bottomArc}
        opacity={props.opacity}
        d='M 17.378,16.783
           a 9.669,9.669 0,0,1 -7.894,4.098
             9.618,9.618 0,0,1 -3.982,-0.865
           l 0.19,0.139
           a 9.402,9.402 0,0,0 5.472,1.75
             9.5,9.5 0,0,0 7.755,-4.027
             9.493,9.493 0,0,0 1.605,-7.111
             9.47,9.47 0,0,0 -4.065,-6.3
             9.7,9.7 0,0,1 0.919,12.316' />
      <path fill={props.bird}
        d='M 8.195 12.766
           c -0.115,0.031 -0.995,0.036 -4.445,-0.197 -0.401,-0.027
             -0.116,1.656 0.343,1.96 0.313,0.207 1.259,0.113 1.259,0.113
           s 0,0.148 -0.168,0.228
           l -0.682,0.323
           c -0.347,0.422 1.758,1.774 2.982,1.412
           l 1.34,-0.85
           s -0.034,0.246 -0.173,0.443
           c -0.074,0.105 -0.27,0.298 -0.593,0.657 0,0 0.94,0.077 1.295,-0.371
           a 65.28,65.28 0,0,1 1.231,-1.588
           s 2.647,2.289 3.612,2.728
           c 1.665,0.756 2.405,0.993 3.52,1.548 0.999,0.496 3.103,0.92
             4.018,0.383 0.827,-0.486 1.087,-0.957 1.087,-0.957
           l -8.88,-5.98
           s 0.544,-0.33 1.26,-0.789
           c 0.23,-0.147 0.83,-0.032 0.83,-0.032
           s -0.05,-0.431 -0.59,-0.617
           c -0.377,-0.128 -1.158,-0.422 -1.158,-0.422 -0.31,0.067
             -1.803,0.511 -2.881,-0.34
           C 10.152,9.431 3.787,1.091 2.436,0
           c 0,0 -0.424,0.335 -0.704,0.807 -0.758,1.277
             -0.476,2.447 -0.086,3.118
           a 55.653,55.653 0,0,1 2.36,4.505
           c 0.485,1.05 2.476,2.977 3.125,3.576 0.53,0.492
             1.064,0.76 1.064,0.76' />
    </g>
  </svg>
)

export const BaseLogoIcon = (props) => (
  <span className={cnLogo('Icon', [props.className])}>
    <SVG
      topArc={props.topArc}
      bottomArc={props.bottomArc}
      bird={props.bird}
      opacity={props.opacity} />
  </span>
)

export const LogoIcon = compose(
  LogoIconThemeDefault,
  LogoIconThemeNegative
)(BaseLogoIcon)
