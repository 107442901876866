import React from 'react'
import { compose } from '@bem-react/core'

import { cnHeader } from '../Header'
import { HeaderBurgerThemeDefault } from './_theme/Header-Burger_theme_default'
import {
  HeaderBurgerThemeNegative
} from './_theme/Header-Burger_theme_negative'
import './Header-Burger.css'

export const BaseHeaderBurger = (props) => (
  <button
    className={cnHeader('Burger', [props.className])}
    onClick={props.onClick}>
    {props.icon}
  </button>
)

export const HeaderBurger = compose(
  HeaderBurgerThemeDefault,
  HeaderBurgerThemeNegative
)(BaseHeaderBurger)
