import React from 'react'
import { withBemMod } from '@bem-react/core'

import { cnLogo } from '../../Logo'

const newBody = (Base, props) => {
  const newProps = Object.assign({}, props)
  newProps.topArc = '#fff'
  newProps.bottomArc = '#fff'
  newProps.bird = '#cecece'
  newProps.opacity = '0.5'

  return <Base {...newProps} />
}

export const LogoIconThemeNegative = withBemMod(
  cnLogo('Icon'),
  { theme: 'negative' },
  newBody
)
