import { compose } from '@bem-react/core'

import { Heading as BaseHeading } from './Heading'
import { HeadingSizeL } from './_size/Heading_size_l'
import { HeadingSizeM } from './_size/Heading_size_m'
import { HeadingSizeS } from './_size/Heading_size_s'

const Heading = compose(
  HeadingSizeL,
  HeadingSizeM,
  HeadingSizeS
)(BaseHeading)

export default Heading
