import React from 'react'

import Button from '../../Button'
import { cnFooter } from '../Footer'
import { FooterSocialMedia } from '../-SocialMedia/Footer-SocialMedia'
import { FooterHeading } from '../-Heading/Footer-Heading'

export const FooterAside = (props) => (
  <div className={cnFooter('Aside')}>
    <FooterHeading>Каналы и другие медиа</FooterHeading>
    <FooterSocialMedia />

    <Button
      theme='emerald'
      type={props.isPressButtonDisabled ? 'disabled' : 'link'}
      uri='/press'>
      Для прессы
    </Button>
  </div>
)
