import React from 'react'
import { cn } from '@bem-react/classname'

import './Heading.css'

export const cnHeading = cn('Heading')

export const Heading = (props) => {
  const { children, className, tag, ...rest } = props
  const Tag = tag || 'span'

  return (
    <Tag className={cnHeading(null, [className])} {...rest}>
      {children}
    </Tag>
  )
}
