import React from 'react'

import Footer from '../../Footer'
import { cnPage } from '../Page'
import './Page-Footer.css'

export const PageFooter = (props) => {
  const newProps = Object.assign({}, props)
  newProps.className = cnPage('Footer')

  return <Footer {...newProps} />
}
