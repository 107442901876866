import React from 'react'

import { cnIllust } from '../Illust'
import './Illust-Picture.css'

export const IllustPicture = (props) => (
  <img
    alt={props.alt}
    className={cnIllust('Picture')}
    src={props.uri} />
)
