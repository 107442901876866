import React from 'react'

import { FileDLLink } from '../-Link/FileDL-Link.js'
import { cnFileDL } from '../FileDL.js'
import './FileDL-Links.css'

export const FileDLLinks = ({ links }) => (
  <ul className={cnFileDL('Links')}>
    {links.map((linkProps, i) => (
      <li key={i}>
        <FileDLLink {...linkProps} />
      </li>
    ))}
  </ul>
)
