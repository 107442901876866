import React from 'react'

import { cnOList } from '../OList'
import './OList-Item.css'

export const OListItem = (props) => (
  <li className={cnOList('Item')}>
    {props.children}
  </li>
)
