import { compose } from '@bem-react/core'

import { Header as BaseHeader } from './Header'
import { HeaderThemeDefault } from './_theme/Header_theme_default'
import { HeaderThemeNegative } from './_theme/Header_theme_negative'

const Header = compose(
  HeaderThemeDefault,
  HeaderThemeNegative
)(BaseHeader)

export default Header
