import React from 'react'

import { EditorPictureUpload } from '../-PictureUpload/Editor-PictureUpload'
import { cnEditor } from '../Editor'
import { PictureIcon } from './PictureIcon.js'

import './Editor-PictureButton.css'

export const EditorPictureButton = (props) => (
  <label className={cnEditor('PictureButton')}>
    <PictureIcon />
    <EditorPictureUpload {...props} />
  </label>
)
