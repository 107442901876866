import React from 'react'
import { cn } from '@bem-react/classname'

import './Link.css'

export const cnLink = cn('Link')

export const Link = (props) => {
  const { children, className, ...rest } = props

  return (
    <a className={cnLink(null, [className])} {...rest}>
      {children}
    </a>
  )
}
