import React from 'react'
import { cn } from '@bem-react/classname'

import { ButtonIcon } from './-Icon/Button-Icon'
import { ButtonText } from './-Text/Button-Text'
import './Button.css'

export const cnButton = cn('Button')

export const Button = (props) => (
  <button className={cnButton(null, [props.className])}>
    <ButtonIcon>{props.icon}</ButtonIcon>
    <ButtonText>{props.children}</ButtonText>
  </button>
)
