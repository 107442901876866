import React from 'react'

import { cnMenu } from '../Menu'
import { MenuList } from '../-List/Menu-List'
import './Menu-Nav.css'

export const MenuNav = (props) => (
  <nav className={cnMenu('Nav')}>
    <MenuList activeItem={props.activeItem} />
  </nav>
)
