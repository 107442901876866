import React from 'react'

import { cnContact } from '../Contact'
import './Contact-Photo.css'

export const ContactPhoto = (props) => (
  <img
    className={cnContact('Photo')}
    alt=''
    src={props.src}
    srcSet={props.srcSet} />
)
